import { createFileRoute, Outlet, useLoaderData } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient';
import { requireAuthenticated } from '@/common/api/requireAuthenticated';
import { getDepositAccountsCount } from '@/features/portal/api/user-deposit-accounts';
import UserDashboard from '@/features/portal/UserDashboard.tsx';
import { EmptyLayout } from '@/components/ui/layout/EmptyLayout.tsx';

export const Route = createFileRoute('/portal')({
  beforeLoad: (params) => requireAuthenticated(params),
  loader: () => crownQueryClient.fetchQuery(getDepositAccountsCount),
  head: () => ({ meta: [{ title: 'Home' }] }),
  component: () => {
    const count = useLoaderData({
      from: '/portal',
      select: (data) => data.count,
    });
    return count ? (
      <UserDashboard content={<Outlet />} />
    ) : (
      <EmptyLayout>
        <Outlet />
      </EmptyLayout>
    );
  },
});
