import { UserGroupIcon } from '@heroicons/react/24/outline';
import { createLazyRoute, Link, useSearch } from '@tanstack/react-router';

import { QueriedList } from '@/components/list/QueriedList.tsx';
import { QueriedListItem } from '@/components/list/QueriedListItem.tsx';
import { Button } from '@/components/ui/Button.tsx';
import DashboardHeader from '@/components/ui/layout/dashboard-layout/DashboardHeader.tsx';
import { SearchWidget } from '@/components/ui/layout/dashboard-layout/widget/SearchWidget.tsx';
import { listProvidersInfiniteQuery } from '@/features/administrator-panel/provider/api/providersApi';
import { ProviderListItem } from '@/features/administrator-panel/provider/ui/ProviderListItem.tsx';

export const PROVIDERS_PER_PAGE = 20;

export const ProviderManagement = () => {
  const search = useSearch({ from: '/admin/providers/' });

  return (
    <>
      <div
        className={
          'block w-full max-w-dashboard-content items-center gap-x-8 md:flex'
        }
      >
        <DashboardHeader
          className={
            'mb-4 flex items-center justify-center text-center md:mb-0 md:justify-start md:text-left'
          }
        >
          <UserGroupIcon className={'mr-2 size-8 text-gray-800'} />
          Providers
        </DashboardHeader>

        <SearchWidget
          className={'grow'}
          route={'/admin/providers'}
          placeholder={'Search providers...'}
        />
      </div>

      <QueriedList
        className={'mt-8'}
        headerClassName={'mb-1 items-center'}
        query={listProvidersInfiniteQuery({
          limit: PROVIDERS_PER_PAGE,
          filters: { search: search.search || '' },
        })}
        label={'Providers'}
        action={
          <Link to={`/admin/providers/add`}>
            <Button size={'sm'}>Add New Provider</Button>
          </Link>
        }
      >
        {({ item }) => (
          <QueriedListItem key={item.id}>
            <ProviderListItem provider={item} />
          </QueriedListItem>
        )}
      </QueriedList>
    </>
  );
};

export const Route = createLazyRoute('/admin/providers')({
  component: ProviderManagement,
});
