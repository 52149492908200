import { createFileRoute } from '@tanstack/react-router';

import { requireAuthenticated } from '@/common/api/requireAuthenticated';
import { StartNewApplication } from '@/features/portal/start-home/start-new-application/StartNewApplication.tsx';

export const Route = createFileRoute('/portal/start/new/')({
  beforeLoad: requireAuthenticated,
  head: () => ({ meta: [{ title: 'New Application' }] }),
  component: StartNewApplication,
});
