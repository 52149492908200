import { createFileRoute, stripSearchParams } from '@tanstack/react-router';

import { listProvidersInfiniteQuery } from '@/features/administrator-panel/provider/api/providersApi';
import { ProviderManagement, PROVIDERS_PER_PAGE } from '@/features/administrator-panel/provider/ProviderManagement.tsx';
import { crownQueryClient } from '@/common/api/crownQueryClient';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

export const Route = createFileRoute('/admin/providers/')({
  validateSearch: zodValidator(z.object({search: z.string().default('')})),
  search: {
    middlewares: [stripSearchParams({search: ''})]
  },
  loader: () =>
    crownQueryClient.ensureInfiniteQueryData(
      listProvidersInfiniteQuery({ limit: PROVIDERS_PER_PAGE }),
    ),
  component: ProviderManagement,
});
