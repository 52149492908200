import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient';
import { getConfigQuery } from '@/features/administrator-panel/configuration/api/configApi';
import { AdminConfiguration } from '@/features/administrator-panel/configuration/AdminConfiguration.tsx';
import DashboardHeader from '@/components/ui/layout/dashboard-layout/DashboardHeader.tsx';
import { Gear } from '@phosphor-icons/react';
import Spinner from '@/components/ui/Spinner.tsx';

export const Route = createFileRoute('/admin/configuration/')({
  loader: () => crownQueryClient.ensureQueryData(getConfigQuery),
  head: () => ({ meta: [{ title: 'Settings' }] }),
  component: AdminConfiguration,
  pendingComponent: () => (
    <>
      <DashboardHeader>
        <Gear className={'mr-1 size-8'} />
        Configuration
      </DashboardHeader>

      <div
        className={
          'flex h-96 w-full max-w-dashboard-content items-center justify-center'
        }
      >
        <Spinner className={'size-16'} />
      </div>
    </>
  ),
});
