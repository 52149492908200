import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient';
import { getApplicationStatusOptions } from '@/features/provider-panel/application-status/api/applicationStatusApi';
import { ApplicationStatus } from '@/features/provider-panel/application-status/ApplicationStatus.tsx';

export const Route = createFileRoute(
  '/provider/$providerId/applications/status/$applicationId',
)({
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(
      getApplicationStatusOptions(params.providerId, params.applicationId),
    ),
  component: ApplicationStatus,
});
