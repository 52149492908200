import { createFileRoute, useParams } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient';
import { getWireFeeQuery } from '@/features/portal/deposit-accounts/account/api/userDepositAccountTransfersAPI';
import { WireTransfersTable } from '@/features/portal/deposit-accounts/account/transfers/wire/WireTransfersTable.tsx';

export const Route = createFileRoute(
  '/portal/deposit-account/$depositAccountId/home/wires',
)({
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(getWireFeeQuery(params.depositAccountId)),
  head: () => ({ meta: [{ title: 'Wires' }] }),
  component: () => {
    const { depositAccountId } = useParams({ from: '/portal/deposit-account/$depositAccountId/home/wires' });
    return <WireTransfersTable depositAccountId={depositAccountId} />;
  },
});
