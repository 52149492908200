import { Envelope, Phone } from '@phosphor-icons/react';
import { useSuspenseQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { ReactNode } from 'react';

import { formatPhoneNumber } from '@/common/util/formatPhoneNumber';
import HorizontalRule from '@/components/ui/HorizontalRule.tsx';
import { IconType } from '@/components/ui/Input.tsx';
import { useContextMatch } from '@/components/ui/route-previews/buildRoutePreview.tsx';
import { Typography } from '@/components/ui/Typography.tsx';
import { getCustomerQuery } from '@/features/administrator-panel/customer-view/api/customerViewApi';
import { fullName } from '@/lib/fullName';
import { cn } from '@/lib/utils';
import { Pill } from '@/components/ui/Pill.tsx';
import { capitalize } from '@/common/util/capitalize';

dayjs.extend(LocalizedFormat);

const InfoItemLine = ({
  icon: Icon,
  children,
  className,
}: {
  icon: IconType;
  children: ReactNode;
  className?: string;
}) => {
  return (
    <Typography
      className={cn('flex items-center justify-start gap-x-1', className)}
    >
      <Icon className={'size-5 min-h-4 min-w-4 text-muted-foreground'} />
      {children}
    </Typography>
  );
};

export const CustomerPreview = () => {
  const match = useContextMatch<'/admin/customers/$userId'>();
  const { data: user } = useSuspenseQuery(
    getCustomerQuery(match.params.userId),
  );

  return (
    <div>
      <Typography className={'-ml-1 -mt-1 block text-sm italic text-muted-foreground'}>
        {`Signed up ${dayjs(user.signedUpAt).format('ll')}${user.lastLogin ? `, last login ${dayjs(user.lastLogin).format('lll')}` : ''}`}
      </Typography>
      <Typography variant={'h4'}>{fullName(user.individual)}</Typography>
      <InfoItemLine icon={Envelope}>{user.individual.email}</InfoItemLine>
      {user.individual.phone && (
        <InfoItemLine icon={Phone}>
          {formatPhoneNumber(user.individual.phone)}
        </InfoItemLine>
      )}

      <HorizontalRule className={'my-2'} />
      {user.accounts.length === 0 && (
        <Typography variant={'muted'} className={'italic'}>
          Customer is not an account holder
        </Typography>
      )}
      {user.accounts.length > 0 && (
        <>
          <Typography variant={'label'} className={'font-mono font-bold uppercase'}>Accounts</Typography>
          <div className={'pl-4'}>
            {user.accounts.map(account => (
              <Typography className={'flex items-center justify-start gap-x-1'} key={account.id}>
                {account.name}
                <Pill color={'tag'} size={'sm'}>{`${capitalize(account.type)} account`}</Pill>
              </Typography>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
