import { createFileRoute } from '@tanstack/react-router';
import { useUserSession } from '@/common/user-session/useUserSession';
import { useEffect } from 'react';
import { SessionLayout } from '@/features/user-session/SessionLayout.tsx';
import { LoginSpinner } from '@/features/user-session/LoginSpinner.tsx';

export const Route = createFileRoute('/logout')({
  head: () => ({ meta: [{ title: 'Logging out...' }] }),
  component: () => {
    const { logout } = useUserSession();
    useEffect(() => {
      void logout({ redirect: true });
    }, []);
    return (
      <SessionLayout>
        <LoginSpinner />
      </SessionLayout>
    );
  },
});
