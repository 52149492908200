import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Envelope, User } from '@phosphor-icons/react';
import { Pill } from '@/components/ui/Pill.tsx';
import { Components } from '@/openapi';
import { PreviewLink } from '@/components/ui/route-previews/buildRoutePreview.tsx';

export type CustomerListItemProps = {
  customer: Components.Schemas.CustomerListItemDto;
};

export const CustomerListItem = ({ customer }: CustomerListItemProps) => {
  return (
    <div
      className={
        'relative grid grid-cols-4 items-center justify-between p-4 transition-colors hover:bg-primary-foreground'
      }
    >
      <div className={'col-span-2 flex flex-col items-start justify-center'}>
        <span
          className={
            '-mb-1 flex items-center justify-start text-lg font-semibold text-primary'
          }
        >
          <User className={'mr-1 inline size-5'}/>
          {`${customer.firstName} ${customer.lastName}`}
        </span>
        <span
          className={'flex items-center justify-start text-sm text-gray-700'}
        >
          <Envelope className={'mr-1 size-5 text-gray-400'}/>
          {customer.email}
        </span>

        <PreviewLink
          to={'/admin/customers/$userId'}
          params={{userId: customer.id}}
        >
          <span className={'absolute inset-x-0 -top-px bottom-0'}/>
        </PreviewLink>
      </div>

      <div className={'col-span-1 hidden sm:block'}>
        {(customer.hasDepositAccounts || customer.hasVirtualAccounts) && (
          <Pill color={'primary'}>
            Account Holder
          </Pill>
        )}
      </div>

      <div className={'col-span-2 flex items-center justify-end sm:col-span-1'}>
        <ChevronRightIcon className={'size-6 text-gray-400'}/>
      </div>
    </div>
  );
};

export const CustomerListItemSkeletons = ({count}: { count: number }) => (
  <>
    {Array.from({ length: count }).map((_, i) => (
      <CustomerListItemSkeleton key={i} />
    ))}
  </>
);

export const CustomerListItemSkeleton = () => {
  return (
    <div className={'w-full p-2 py-4'}>
      <div className={'h-10 w-full animate-pulse rounded-sm bg-gray-300'} />
    </div>
  );
};
