import { CloudX } from '@phosphor-icons/react';
import { ErrorBoundary } from '@sentry/react';
import { HTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconType } from '@/components/ui/Input.tsx';
import SurfaceLabel from '@/components/ui/layout/dashboard-layout/SurfaceLabel.tsx';
import { Typography } from '@/components/ui/Typography.tsx';
import { cn } from '@/lib/utils';

export type ContentCardProps = {
  children: ReactNode;
  header?: ReactNode;
  headerIcon?: IconType;
  containerClassName?: string;
} & HTMLAttributes<HTMLDivElement>;

export const ContentCard = ({
  children,
  header,
  containerClassName,
  headerIcon,
  ...props
}: ContentCardProps) => (
  <div className={cn('max-w-dashboard-content w-full', containerClassName)}>
    {typeof header === 'string' ? (
      <SurfaceLabel icon={headerIcon}>{header}</SurfaceLabel>
    ) : (
      header
    )}
    <div
      {...props}
      className={twMerge(
        'w-full rounded-xl shadow-md border bg-white p-4 overflow-hidden',
        props.className,
      )}
    >
      <ErrorBoundary
        fallback={
          <div className={'flex flex-col items-center justify-center gap-y-2 p-4 py-12'}>
            <CloudX className={'size-10 text-error/80'} />

            <Typography variant={'h4'} className={'text-center text-error'}>
              An internal server error occurred loading this widget
            </Typography>
          </div>
        }
      >
        {children}
      </ErrorBoundary>
    </div>
  </div>
);
