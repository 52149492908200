import {
  Dialog as HeadlessDialog,
  DialogProps as HeadlessDialogProps,
  Transition,
} from '@headlessui/react';
import { Fragment, ReactNode, useRef } from 'react';

import { cn } from '@/lib/utils';

export type DialogProps = {
  size?: 'small' | 'medium' | 'large';
  open: boolean;
  onClose?: () => void;
  children: ReactNode;
  className?: string;
} & Omit<HeadlessDialogProps<'div'>, 'onClose' | 'unmount'>;

const sizeClasses = {
  small: 'sm:max-w-lg',
  medium: 'sm:max-w-2xl',
  large: 'sm:max-w-4xl',
};

const Dialog = ({
  open,
  onClose,
  size,
  children,
  className,
  ...props
}: DialogProps) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <HeadlessDialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => onClose?.()}
        unmount={undefined}
        {...props}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <HeadlessDialog.Panel
                className={cn(
                  'relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:p-6',
                  sizeClasses[size || 'medium'],
                  className,
                )}
              >
                {children}
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  );
};

export default Dialog;
