import { Vault } from '@phosphor-icons/react';
import { createLazyRoute, useSearch } from '@tanstack/react-router';

import { QueriedList } from '@/components/list/QueriedList.tsx';
import DashboardHeader from '@/components/ui/layout/dashboard-layout/DashboardHeader.tsx';
import { SearchWidget } from '@/components/ui/layout/dashboard-layout/widget/SearchWidget.tsx';
import { DepositAccountListItem } from '@/components/ui/deposit-accounts/DepositAccountListItem.tsx';
import Spinner from '@/components/ui/Spinner.tsx';
import { listDepositAccountsQuery } from '@/features/administrator-panel/deposit-accounts/api/depositAccountsApi';
import { cn } from '@/lib/utils';

export const DEPOSIT_ACCOUNTS_PER_PAGE = 10;

export const DepositAccounts = () => {
  const { search } = useSearch({from: '/admin/deposit-accounts/'})

  return (
    <>
      <div
        className={
          'block w-full max-w-dashboard-content items-center gap-x-8 md:flex'
        }
      >
        <DashboardHeader
          className={
            'mb-4 flex items-center justify-center text-center md:mb-0 md:justify-start md:text-left'
          }
        >
          <Vault className={'mr-2 size-8 text-gray-800'} />
          Deposit Accounts
          <Spinner
            className={cn('ml-2 w-8 h-8 text-gray-300', {
              'sm:invisible hidden sm:inline-block': true,
            })}
          />
        </DashboardHeader>

        <SearchWidget
          className={'grow'}
          route={'/admin/deposit-accounts'}
          placeholder={'Search accounts...'}
        />
      </div>
      <QueriedList
        empty={{
          title: 'No accounts found',
          message: 'Try searching for something else',
        }}
        className={'mt-8'}
        label={'Deposit Accounts'}
        query={listDepositAccountsQuery({ limit: DEPOSIT_ACCOUNTS_PER_PAGE, search })}
      >
        {({ item }) => <DepositAccountListItem key={item.id} depositAccount={item} />}
      </QueriedList>
    </>
  );
};

export const Route = createLazyRoute('/admin/deposit-accounts')({
  component: DepositAccounts,
});
