import { createFileRoute } from '@tanstack/react-router'
import { requireAuthenticated } from '@/common/api/requireAuthenticated';
import { crownQueryClient } from '@/common/api/crownQueryClient';
import { getApplicationStatusOptions } from '@/features/application-status/api/applicationStatusApi';
import { ApplicationStatus } from '@/features/application-status/ApplicationStatus.tsx';
import { ApplicationLoader } from '@/components/ui/applications/ApplicationLoader.tsx';

export const Route = createFileRoute('/application-status/$applicationId')({
  beforeLoad: requireAuthenticated,
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(
      getApplicationStatusOptions(params.applicationId),
    ),
  component: ApplicationStatus,
  pendingComponent: ApplicationLoader,
})
