import { queryOptions } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient';

export const getApplicationStatusOptions = (applicationId: string) =>
  queryOptions({
    queryKey: ['application-status', applicationId],
    queryFn: async () =>
      (await crownApi.ApplicationStatus_getApplication({ applicationId })).data,
  });
