import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import '@/common/api/crownQueryClient.ts';

import { AuthProvider } from '@descope/react-sdk';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import { Toaster } from 'react-hot-toast';

import { crownQueryClient } from '@/common/api/crownQueryClient';
import { router } from '@/routing.tsx';

(async () => {
  await import('@/monitoring/sentry.ts');
})();

(async () => {
  await import('react-pdf').then(({ pdfjs }) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });
})();

const App = () => {
  return (
    <>
      <RouterProvider router={router} />
      <Toaster />
    </>
  );
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AuthProvider projectId={import.meta.env.VITE_DESCOPE_PROJECT_ID}>
      <QueryClientProvider client={crownQueryClient}>
        <App />
      </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>,
);
