import { useFormContext } from 'react-hook-form';

import { dotNotationToValue } from '@/common/util/dotNotationToValue';
import { ErrorType } from '@/components/ui/Input.tsx';

export const useFieldState = ({ name }: { name?: string }) => {
  const formContext = useFormContext();
  if (!formContext || !name) {
    return null;
  }

  const formState = formContext.formState;
  return {
    isDirty: formState.dirtyFields[name] === true,
    errors: dotNotationToValue<{ message: ErrorType }>(name, formState.errors),
    isTouched:
      dotNotationToValue<boolean>(name, formState.touchedFields) === true,
  };
};
