import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
} from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient';
import { downloadFile } from '@/lib/downloadFile';
import { Components } from '@/openapi';

export const createOmnibusAccountMutation = {
  mutationFn: async (
    account: Components.Schemas.CreateOmnibusAccountParamsDto,
  ) =>
    (await crownApi.AdminOmnibusAccounts_createOmnibusAccount(null, account))
      .data,
};

export const listOmnibusAccountsQuery = ({
  perPage,
  ...filters
}: Omit<Components.Schemas.ListOmnibusAccountsParamsDto, 'page' | 'limit'> & {
  perPage: number;
}) =>
  infiniteQueryOptions({
    queryFn: async ({ pageParam = 1 }) => {
      return (
        await crownApi.AdminOmnibusAccounts_listAccounts({
          page: pageParam,
          limit: perPage,
          search: filters.search,
        })
      ).data;
    },
    queryKey: ['admin', 'omnibus', 'list', { filters, perPage }],
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    placeholderData: keepPreviousData,
  });

export const getOmnibusAccountQuery = (accountId: string) =>
  queryOptions({
    queryFn: async () => {
      return (
        await crownApi.AdminOmnibusAccountSingle_getOmnibusAccount({
          accountId,
        })
      ).data;
    },
    queryKey: ['admin', 'omnibus', 'get', accountId],
    placeholderData: keepPreviousData,
  });

const accountsListQueryKey = (omnibusId: string, type: string) => [
  'admin',
  'omnibus',
  'get',
  omnibusId,
  'deposit-accounts',
  type,
];

export const listIncomingDepositAccountsQuery = (omnibusAccountId: string) =>
  queryOptions({
    queryFn: () =>
      crownApi
        .OmnibusDepositAccounts_getIncomingDepositAccounts({ omnibusAccountId })
        .then((r) => r.data),
    queryKey: accountsListQueryKey(omnibusAccountId, 'incoming'),
  });

export const listUnassignedAccountsQuery = (omnibusAccountId: string) =>
  queryOptions({
    queryFn: () =>
      crownApi
        .OmnibusDepositAccounts_getUnassignedDepositAccounts({
          omnibusAccountId,
        })
        .then((r) => r.data),
    queryKey: accountsListQueryKey(omnibusAccountId, 'unassigned'),
  });

export const listSweepAccountsQuery = (omnibusAccountId: string) =>
  queryOptions({
    queryFn: () =>
      crownApi
        .OmnibusDepositAccounts_getSweepDepositAccounts({ omnibusAccountId })
        .then((r) => r.data),
    queryKey: accountsListQueryKey(omnibusAccountId, 'sweep'),
  });

export const listFundingDepositAccountsQuery = (omnibusAccountId: string) =>
  queryOptions({
    queryFn: () =>
      crownApi
        .OmnibusDepositAccounts_getFundingDepositAccounts({ omnibusAccountId })
        .then((r) => r.data),
    queryKey: accountsListQueryKey(omnibusAccountId, 'funding'),
  });

export const downloadVirtualAccountsCSVMutationFn = async (
  omnibusId: string,
) => {
  const data = await crownApi.AdminOmnibusAccounts_exportVirtualAccounts(null, {
    omnibusId,
  });
  downloadFile(data.data.csvUrl, 'Omnibus Export.csv');
};
