import { infiniteQueryOptions, keepPreviousData } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient';
import { Components } from '@/openapi';

export const listApplicationsInfiniteQuery = ({
  limit = 10,
  filters,
  providerId,
}: Omit<Components.Schemas.ListApplicationsParamsDto, 'page'> & {
  providerId?: string;
}) =>
  infiniteQueryOptions({
    queryFn: async ({ pageParam = 1, signal }) =>
      (
        await crownApi.ProviderApplications_listApplications(
          {
            providerId: providerId as string,
            page: pageParam,
            limit,
            filters,
          },
          null,
          {
            signal,
          },
        )
      ).data,
    queryKey: ['provider', 'applications', { limit, filters }],
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    getPreviousPageParam: (firstPage) => firstPage.prevCursor,
    initialPageParam: 1,
    placeholderData: keepPreviousData,
  });
