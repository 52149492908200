import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
} from '@tanstack/react-query';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { isAxiosError, type AxiosError } from 'axios';

import { crownApi } from '@/common/api/crownQueryClient';
import { Components } from '@/openapi';

export const listCustomersQuery = ({
  perPage,
  ...filters
}: Components.Schemas.CustomersFiltersDto & {
  perPage: number;
}) =>
  infiniteQueryOptions({
    queryFn: async ({ pageParam = 1 }) => {
      return (
        await crownApi.AdminCustomerManagement_listCustomers({
          page: pageParam,
          limit: perPage,
          filters,
        })
      ).data;
    },
    queryKey: ['admin', 'customers', 'list', { filters, perPage }],
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    placeholderData: keepPreviousData,
  });

export const getCustomerByEmailQuery = (email: string) =>
  queryOptions({
    queryFn: async () => {
      try {
        return (
          await crownApi.AdminCustomerManagement_getCustomerByEmail({ email })
        ).data;
      } catch (error: AxiosError | unknown) {
        if (isAxiosError(error) && error.response!.status === 404) {
          return null;
        }
        throw error;
      }
    },
    queryKey: ['admin', 'customers', 'getByEmail', email],
    retry: false,
  });
