import { queryOptions } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient';

export const queryUserDepositAccounts = queryOptions({
  queryFn: async () =>
    (await crownApi.UserDepositAccount_getDepositAccounts()).data,
  queryKey: ['user-deposit-accounts', 'list'],
});

export const queryUserDepositAccount = (depositAccountId: string) =>
  queryOptions({
    queryFn: async () =>
      (
        await crownApi.SingleUserDepositAccount_getDepositAccount({
          accountId: depositAccountId,
        })
      ).data,
    queryKey: ['user-deposit-accounts', 'get', depositAccountId],
  });
