import { createFileRoute } from '@tanstack/react-router'
import { listProxiesQuery } from '@/features/administrator-panel/proxy/api/proxiesApi';
import { PROXIES_PER_PAGE } from '@/features/administrator-panel/proxy/ProxyConfigurationsList.tsx';
import { crownQueryClient } from '@/common/api/crownQueryClient';
import { ProxyConfigurations } from '@/features/administrator-panel/proxy/ProxyConfigurations.tsx';

export const Route = createFileRoute('/admin/configuration/proxy/')({
  loader: () => crownQueryClient.ensureInfiniteQueryData(listProxiesQuery({limit: PROXIES_PER_PAGE})),
  component: ProxyConfigurations,
})
