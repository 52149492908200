import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Anchor } from '@phosphor-icons/react';
import { Link } from '@tanstack/react-router';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { formatCurrency } from '@/common/util/formatCurrency.tsx';
import { Button } from '@/components/ui/Button.tsx';
import { ContentCard } from '@/components/ui/layout/dashboard-layout/ContentCard.tsx';
import { Typography } from '@/components/ui/Typography.tsx';
import { Components } from '@/openapi';

dayjs.extend(relativeTime);

type OmnibusAccountGridItemProps = {
  omnibus: Components.Schemas.OmnibusAccountListItemDto;
};

export const OmnibusAccountGridItem = ({
  omnibus,
}: OmnibusAccountGridItemProps) => {

  return (
    <ContentCard
      className={'relative'}
      containerClassName={'col-span-1'}
    >
      <Typography variant={'muted'} className={'absolute left-2 top-1 text-sm'}>
        {omnibus.lastRefreshed
          ? 'Refreshed ' + dayjs(omnibus.lastRefreshed, { utc: true }).fromNow()
          : ''}
      </Typography>

      <Typography variant={'h4'} className={'mt-2 text-center text-primary'}>
        {omnibus.isDefault && <Anchor className={'mr-2 inline size-6'} />}
        {omnibus.name}
        {omnibus.isDefault && ' (default)'}
      </Typography>

      <Typography>
        <span className={'text-muted-foreground'}>
          Total Accounts Balance:{' '}
        </span>
        {formatCurrency(omnibus.totalAccountsBalance)}
      </Typography>

      <Typography>
        <span className={'text-muted-foreground'}>Virtual Accounts #: </span>
        {omnibus.virtualAccountsCount}
      </Typography>

      <Typography>
        <span className={'text-muted-foreground'}>Open Applications #: </span>
        {omnibus.openApplicationsCount}
      </Typography>

      <div className={'mt-4 flex justify-center gap-x-2'}>
        <Link
          to={`/admin/configuration/omnibus/$omnibusAccountId`}
          params={{ omnibusAccountId: omnibus.id }}
        >
          <Button variant={'outline'}>
            View Account
            <ChevronRightIcon
              className={'ml-2 size-4 text-muted-foreground'}
            />
          </Button>
        </Link>
      </div>
    </ContentCard>
  );
};
