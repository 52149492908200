import { lazy, Suspense } from 'react';

import InboxLottie from '@/components/lottie/Inbox.json';
import { Typography } from '@/components/ui/Typography.tsx';

const Lottie = lazy(() => import('lottie-react'));

export type EmptyListStateProps = {
  title: string;
  message: string;
};

export const EmptyListState = ({ title, message }: EmptyListStateProps) => {
  return (
    <div
      className={
        'flex size-full max-h-96 flex-col items-center justify-center p-12'
      }
    >
      <Suspense fallback={null}>
        <Lottie animationData={InboxLottie} style={{ height: 112 }} />
      </Suspense>
      <Typography variant={'h3'} className={'text-center'}>
        {title}
      </Typography>
      <Typography variant={'muted'} className={'mt-4 text-center md:mt-0'}>
        {message}
      </Typography>
    </div>
  );
};
