import {
  DefaultError,
  queryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';

import { crownApi, crownQueryClient } from '@/common/api/crownQueryClient';
import { Components } from '@/openapi';

export const getTeamMemberUserQuery = (userId: string) =>
  queryOptions({
    queryFn: async () => (await crownApi.Team_getUser({ userId })).data,
    queryKey: ['admin', 'team', 'user', { userId }],
  });

export const updateUserMutation: UseMutationOptions<
  Components.Schemas.UpdateUserResponseDto,
  DefaultError,
  Components.Schemas.UpdateUserParamsDto & {
    userId: string;
  }
> = {
  mutationFn: async (user) => {
    return (await crownApi.Team_updateUser(user.userId, user)).data;
  },
  onSuccess: (data) => {
    void crownQueryClient.invalidateQueries({
      queryKey: ['admin', 'team', 'user', { userId: data.userId }],
    });
  },
};

export const removeUserMutation: UseMutationOptions<
  Components.Schemas.UpdateUserResponseDto,
  DefaultError,
  { userId: string }
> = {
  mutationFn: async ({ userId }) => {
    return (await crownApi.Team_removeUser(userId)).data;
  },
  onSuccess: () => {
    void crownQueryClient.invalidateQueries({
      queryKey: ['admin', 'team', 'list'],
    });
  },
};
