import { HTMLAttributes, ReactNode } from 'react';

import { cn } from '@/lib/utils';

type DashboardHeaderProps = {
  children: ReactNode;
} & HTMLAttributes<HTMLParagraphElement>;

const DashboardHeader = ({
  children,
  className,
  ...props
}: DashboardHeaderProps) => (
  <p {...props} className={cn('text-3xl font-medium text-center flex items-center', className)}>
    {children}
  </p>
);

export default DashboardHeader;
