import { infiniteQueryOptions, keepPreviousData } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient';
import { Components } from '@/openapi';

export type ListFundingAccountsParams = Omit<
  Components.Schemas.ListFundingSourceAccountsParamsDto,
  'page'
>;

export const fundingAccountQueryKeys = {
  all: ['admin', 'funding-accounts'] as const,
  listAll: () => [...fundingAccountQueryKeys.all, 'list'] as const,
  list: (params: ListFundingAccountsParams) =>
    [...fundingAccountQueryKeys.listAll(), params] as const,
};

export const listFundingAccountsInfiniteQuery = (
  params: ListFundingAccountsParams,
) =>
  infiniteQueryOptions({
    queryFn: async ({ pageParam = 1 }) => {
      return (
        await crownApi.FundingSourceAccountAdmin_listFundingAccounts({
          page: pageParam,
          limit: params.limit,
        })
      ).data;
    },
    queryKey: fundingAccountQueryKeys.list(params),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    placeholderData: keepPreviousData,
  });
