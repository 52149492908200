import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient';
import { getConfigQuery } from '@/features/administrator-panel/configuration/api/configApi';
import { FeatureFlagsPage } from '@/features/administrator-panel/configuration/FeatureFlagsPage.tsx';

export const Route = createFileRoute('/admin/configuration/features/')({
  loader: () => crownQueryClient.ensureQueryData(getConfigQuery),
  head: () => ({ meta: [{ title: 'Features' }] }),
  component: FeatureFlagsPage
});
