import { cn } from '@/lib/utils';

type HorizontalRuleProps = {
  /**
   * Additional class names to apply to the horizontal rule
   */
  className?: string;
};

/**
 * A basic horizontal rule. In order for it to render correctly, it should be wrapped in a block-level element with a fixed width.
 */
const HorizontalRule = ({ className }: HorizontalRuleProps) => {
  return <hr className={cn('h-px bg-gray-400', className)} />;
};

export default HorizontalRule;
