export const QueriedListItemSkeleton = () => {
  return (
    <div className={'w-full p-2 py-4'}>
      <div className={'h-10 w-full animate-pulse rounded-sm bg-gray-300'} />
    </div>
  );
};

export const QueriedListItemSkeletons = ({ count }: { count: number }) => (
  <>
    {Array.from({ length: count }).map((_, i) => (
      <QueriedListItemSkeleton key={`skeleton-${i}`} />
    ))}
  </>
);
