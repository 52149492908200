import { createFileRoute, redirect } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient';
import { requireAuthenticated } from '@/common/api/requireAuthenticated';
import { checkAuthQuery } from '@/features/administrator-panel/api/adminstratorPanelApi';
import { AdministratorPanel } from '@/features/administrator-panel/AdministratorPanel.tsx';
import { ErrorState } from '@/components/ui/states/ErrorState.tsx';

export const Route = createFileRoute('/admin')({
  beforeLoad: async (params) => {
    await requireAuthenticated(params);
    try {
      await crownQueryClient.fetchQuery(checkAuthQuery);
    } catch (error) {
      throw redirect({
        to: '/',
        replace: true,
      });
    }
  },
  head: () => ({ meta: [{ title: 'Admin' }] }),
  component: AdministratorPanel,
  errorComponent: ErrorState,
  notFoundComponent: () => (
    <ErrorState error={"We couldn't find the page you were trying to reach"} />
  ),
});
