import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { HTMLAttributes, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { Typography } from '@/components/ui/Typography.tsx';
import { listOmnibusAccountsQuery } from '@/features/administrator-panel/omnibus/api/omnibusAccountApi';
import { OmnibusAccountGridItem } from '@/features/administrator-panel/omnibus/OmnibusAccountGridItem.tsx';
import { cn } from '@/lib/utils';

export const OMNIBUS_ACCOUNTS_PER_PAGE = 10;

export const OmnibusAccountsGrid = ({
  ...props
}: Omit<HTMLAttributes<HTMLDivElement>, 'children'>) => {
  const { data, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useSuspenseInfiniteQuery(
      listOmnibusAccountsQuery({ perPage: OMNIBUS_ACCOUNTS_PER_PAGE }),
    );
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && !isFetchingNextPage && hasNextPage) void fetchNextPage();
  }, [fetchNextPage, isFetchingNextPage, hasNextPage, inView]);

  return (
    <>
      <div
        {...props}
        className={cn(
          'w-full max-w-dashboard-content grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6',
          props.className,
        )}
      >
        {data?.pages?.map((page) =>
          page.items.map((omnibus) => (
            <OmnibusAccountGridItem omnibus={omnibus} key={omnibus.id} />
          )),
        )}
        <span ref={ref} hidden />
      </div>

      <Typography
        variant={'h3'}
        className={'text-center'}
        hidden={data && data.pages[0]?.items?.length !== 0}
      >
        No accounts have been created yet
      </Typography>
    </>
  );
};
