import { queryOptions } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient';

export const queryApplicationOptions = (
  providerId: string,
  applicationId: string,
) =>
  queryOptions({
    queryFn: async () =>
      (
        await crownApi.ProviderApplications_getApplication({
          providerId,
          applicationId,
        })
      ).data,
    queryKey: ['provider-application', providerId, applicationId],
  });
