import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient';
import { getProviderTeamMemberUserQuery } from '@/features/provider-panel/team/team-member-view/api/providerTeamMemberApi';
import { fullName } from '@/lib/fullName';
import { ProviderTeamMemberView } from '@/features/provider-panel/team/team-member-view/ProviderTeamMemberView.tsx';

export const Route = createFileRoute('/provider/$providerId/team/$userId')({
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(
      getProviderTeamMemberUserQuery(params.providerId, params.userId),
    ),
  head: ({ loaderData }) => ({
    meta: [
      {
        title: loaderData ? fullName(loaderData) : 'Team Member',
      },
    ],
  }),
  component: ProviderTeamMemberView,
});
