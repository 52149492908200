import { HTMLAttributes, ReactNode } from 'react';

import { cn } from '@/lib/utils';

type HelperTextProps = {
  children: ReactNode;
} & HTMLAttributes<HTMLSpanElement>;

const HelperText = ({ children, ...props }: HelperTextProps) => (
  <span className={cn('text-sm text-gray-600 italic block', props.className)}>{children}</span>
);

export default HelperText;
