import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
} from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient';
import { Paths } from '@/openapi';

type InfiniteListParams = Omit<
  Paths.ProviderAdminListProviders.QueryParameters,
  'page'
>;

export const providerQueryKey = {
  base: ['admin', 'providers'] as const,
  listAll: () => [...providerQueryKey.base, 'list'] as const,
  infiniteList: (params: InfiniteListParams) => [
    ...providerQueryKey.listAll(),
    'infinite',
    params,
  ],
  singleAll: () => [...providerQueryKey.base, 'get'],
  single: (providerId: string) => [
    ...providerQueryKey.singleAll(),
    { providerId },
  ],
  portalConfig: (providerId: string) => [
    ...providerQueryKey.single(providerId),
    'portal-config',
  ],
} as const;

export const listProvidersInfiniteQuery = (params: InfiniteListParams) =>
  infiniteQueryOptions({
    queryFn: async ({ pageParam = 1 }) => {
      return (
        await crownApi.ProviderAdmin_listProviders({
          page: pageParam,
          ...params,
        })
      ).data;
    },
    queryKey: providerQueryKey.infiniteList(params),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    placeholderData: keepPreviousData,
  });

export const getProviderQuery = (providerId: string) =>
  queryOptions({
    queryFn: async () =>
      (await crownApi.ProviderAdmin_getProvider({ providerId })).data,
    queryKey: providerQueryKey.single(providerId),
  });

export const getProviderPortalConfigQuery = (providerId: string) =>
  queryOptions({
    queryFn: async () =>
      (await crownApi.ProviderAdmin_getPortalConfiguration({ providerId }))
        .data,
    queryKey: providerQueryKey.portalConfig(providerId),
  });
