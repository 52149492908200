import { createFileRoute, Outlet, useLoaderData } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient';
import { getDepositAccountsCount } from '@/features/portal/api/user-deposit-accounts';
import { SingleCardLayout } from '@/components/ui/layout/SingleCardLayout.tsx';

export const Route = createFileRoute('/portal/start/new')({
  loader: () => crownQueryClient.ensureQueryData(getDepositAccountsCount),
  component: () => {
    const { count } = useLoaderData({ from: '/portal/start/new' });

    return (
      <SingleCardLayout showBars={!count} maxWidth={'max-w-3xl'}>
        <Outlet />
      </SingleCardLayout>
    );
  },
});
