import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { listCustomersQuery } from '@/features/administrator-panel/customer/api/customersApi';
import { CUSTOMERS_PER_PAGE } from '@/features/administrator-panel/customer/CustomersList.tsx';
import { crownQueryClient } from '@/common/api/crownQueryClient';
import { CustomerManagement } from '@/features/administrator-panel/customer/CustomerManagement.tsx';

type AccountFilter = 'HAS_ACCOUNTS' | 'HAS_NO_ACCOUNTS';

export const Route = createFileRoute('/admin/customers/')({
  validateSearch: z
    .object({
      search: z.string().optional(),
      accountsFilters: z
        .array(
          z.enum<AccountFilter, [AccountFilter, ...AccountFilter[]]>([
            'HAS_ACCOUNTS',
            'HAS_NO_ACCOUNTS',
          ]),
        )
        .optional()
        .catch(() => []),
    })
    .default({}),
  loader: () =>
    crownQueryClient.ensureInfiniteQueryData(
      listCustomersQuery({ perPage: CUSTOMERS_PER_PAGE }),
    ),
  component: CustomerManagement,
});
