import { APPLICATION_TYPE_LABELS } from '@/common/i18n/applicationTypeLabels';
import { QueriedListItem } from '@/components/list/QueriedListItem.tsx';
import { Pill } from '@/components/ui/Pill.tsx';
import { Typography } from '@/components/ui/Typography.tsx';
import { Components } from '@/openapi';
import { formatCurrency } from '@/common/util/formatCurrency.tsx';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(LocalizedFormat);

type DepositAccountListItemProps = {
  depositAccount: Components.Schemas.DepositAccountListItemDto;
};

export const DepositAccountListItem = ({
  depositAccount,
}: DepositAccountListItemProps) => {
  const openedOn = dayjs(depositAccount.openedAt).format('L');

  return (
    <QueriedListItem
      key={depositAccount.id}
      className={'grid grid-cols-2 p-4'}
      link={{
        to: '/admin/deposit-accounts',
      }}
    >
      <div className={'flex flex-col items-start justify-center'}>
        <Typography variant={'h4'} className={'-mb-1 text-lg text-primary'}>
          {depositAccount.name}
          <Pill color={'tag'} size={'sm'} className={'ml-2'}>{`**${depositAccount.lastFour}`}</Pill>
        </Typography>
        <div className={'text-gray-500'}>
          {`Opened by ${depositAccount.primarySigner.firstName} ${depositAccount.primarySigner.lastName} on ${openedOn}`}
        </div>
      </div>
      <div className={'flex items-center justify-between'}>
        <Pill color={'tag'}>
          {APPLICATION_TYPE_LABELS[depositAccount.accountType]}
        </Pill>
        <Typography
          variant={'muted'}
          className={'text-lg'}
        >{formatCurrency(depositAccount.availableBalance)}</Typography>
      </div>
    </QueriedListItem>
  );
};
