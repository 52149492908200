import { create } from 'zustand';

import { ExcludeFunctions } from '@/common/types/ExcludeFunctions';

export type ApplicationsStore = {
  clear: () => void;
  totalApplications: number | null;
  showSpinner: boolean;
  setShowSpinner: (showSpinner: boolean) => void;
  updateTotalApplicationsCount: (totalApplications: number | null) => void;
};

const defaultStore: ExcludeFunctions<ApplicationsStore> = {
  totalApplications: null,
  showSpinner: false,
} as const;

export const useApplicationsState = create<ApplicationsStore>((set) => ({
  clear: () => void set(useApplicationsState.getInitialState()), // See clearAppState
  ...defaultStore,
  updateTotalApplicationsCount: (totalApplications) =>
    set({ totalApplications }),
  setShowSpinner: (showSpinner) => set({ showSpinner }),
}));
