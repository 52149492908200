import { createFileRoute } from '@tanstack/react-router'
import { crownQueryClient } from '@/common/api/crownQueryClient';
import {
  listFundingAccountsInfiniteQuery
} from '@/features/administrator-panel/funding-accounts/api/fundingAccountsQueries';
import { FundingAccountsIndex } from '@/features/administrator-panel/funding-accounts/FundingAccountsIndex.tsx';

export const Route = createFileRoute('/admin/configuration/funding-accounts/')({
  loader: () => crownQueryClient.ensureInfiniteQueryData(listFundingAccountsInfiniteQuery({limit: 20})),
  component: FundingAccountsIndex,
})
