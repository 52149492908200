import { Link } from '@tanstack/react-router';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Envelope, Phone, Users } from '@phosphor-icons/react';

import { formatPhoneNumber } from '@/common/util/formatPhoneNumber';
import { Provider } from '@/features/administrator-panel/provider/types';

type ProviderListItemProps = {
  provider: Provider;
};

export const ProviderListItem = ({
  provider,
}: ProviderListItemProps) => {
  return (
    <div
      className={
        'relative grid w-full grid-cols-2 p-4 transition-colors hover:bg-primary-foreground'
      }
    >
      <div className={'flex flex-col items-start justify-center'}>
        <span
          className={
            '-mb-1 flex items-center justify-start text-lg font-semibold text-primary'
          }
        >
          <Users className={'mr-1 inline size-5'}/>
          {provider.name}
          {provider.isDefault && (' (default)')}
        </span>
        <span
          className={'flex items-center justify-start text-sm text-gray-700'}
        >
          <Phone className={'mr-1 size-5 text-gray-400'}/>
          {formatPhoneNumber(provider.supportPhone)}
        </span>
        <span
          className={'flex items-center justify-start text-sm text-gray-700'}
        >
          <Envelope className={'mr-1 size-5 text-gray-400'}/>
          {provider.supportEmail}
        </span>
        <Link
          to={'/admin/providers/$providerId'}
          params={{ providerId: provider.id }}
        >
          <span className={'absolute inset-x-0 -top-px bottom-0'}/>
        </Link>
      </div>

      <div className={'flex items-center justify-end'}>
        <ChevronRightIcon className={'size-6 text-gray-400'}/>
      </div>
    </div>
  );
};
