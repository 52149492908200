import {
  DefaultError,
  queryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';

import { crownApi, crownQueryClient } from '@/common/api/crownQueryClient';
import { Components } from '@/openapi';

export const getProviderTeamMemberUserQuery = (
  providerId: string,
  userId: string,
) =>
  queryOptions({
    queryFn: async () =>
      (await crownApi.ProviderTeam_getUser({ providerId, userId })).data,
    queryKey: ['provider', 'team', 'user', { userId }],
  });

export const getUpdateProviderUserMutation = (
  providerId: string,
): UseMutationOptions<
  Components.Schemas.UpdateProviderUserResponseDto,
  DefaultError,
  Components.Schemas.UpdateProviderUserParamsDto & { userId: string }
> => ({
  mutationFn: async (user) => {
    return (
      await crownApi.ProviderTeam_updateUser(
        { providerId, userId: user.userId },
        user,
      )
    ).data;
  },
  onSuccess: (data) => {
    void crownQueryClient.invalidateQueries({
      queryKey: ['provider', 'team', 'user', { userId: data.userId }],
    });
  },
});

export const getRemoveProviderUserMutation = (
  providerId: string,
): UseMutationOptions<
  Components.Schemas.UpdateUserResponseDto,
  DefaultError,
  { userId: string }
> => ({
  mutationFn: async ({ userId }) => {
    return (await crownApi.ProviderTeam_removeUser({ providerId, userId }))
      .data;
  },
  onSuccess: () => {
    void crownQueryClient.invalidateQueries({
      queryKey: ['provider', 'team', 'list'],
    });
  },
});
