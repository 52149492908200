import { queryOptions } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient';

export const getOpenApplicationsCountQuery = queryOptions({
  queryFn: async () =>
    (await crownApi.AdminPortal_getOpenApplicationsCount()).data,
  queryKey: ['admin', 'openApplicationsCount'],
});

export const checkAuthQuery = queryOptions({
  queryFn: async () => (await crownApi.AdminPortal_checkAuth()).data,
  queryKey: ['admin', 'isAdmin'],
  retry: 0,
  staleTime: 1000 * 60 * 60,
  refetchOnMount: false,
});
