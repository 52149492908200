import { infiniteQueryOptions, keepPreviousData } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient';

export const listApiKeysInfiniteQuery = (limit: number) =>
  infiniteQueryOptions({
    queryFn: async ({ pageParam = 1 }) =>
      (await crownApi.APIKeysAdmin_getKeys({ page: pageParam, limit })).data,
    queryKey: ['admin', 'keys', 'list', { limit }],
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    placeholderData: keepPreviousData,
  });
