export const downloadFile = (url: string, name?: string) => {
  const link = document.createElement('a');
  if (name) {
    link.download = name;
  }
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadBlob = (blob: Blob, fileName: string) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob!);
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(link.href);
};
