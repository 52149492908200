import { createFileRoute } from '@tanstack/react-router';

import { listTeamUserQuery } from '@/features/administrator-panel/team/api/teamManagementApi';
import { TEAM_USERS_PER_PAGE } from '@/features/administrator-panel/team/constants';
import { crownQueryClient } from '@/common/api/crownQueryClient';
import { TeamManagement } from '@/features/administrator-panel/team/TeamManagement.tsx';

export const Route = createFileRoute('/admin/configuration/team/')({
  validateSearch: (search: Record<string, unknown>) => {
    return {
      search: (search.search as string) || undefined,
    };
  },
  loader: () =>
    crownQueryClient.ensureInfiniteQueryData(
      listTeamUserQuery({ perPage: TEAM_USERS_PER_PAGE }),
    ),
  component: TeamManagement,
});
