/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as WelcomeImport } from './routes/welcome'
import { Route as LogoutImport } from './routes/logout'
import { Route as LoginImport } from './routes/login'
import { Route as TestingRouteImport } from './routes/testing/route'
import { Route as StartRouteImport } from './routes/start/route'
import { Route as PortalRouteImport } from './routes/portal/route'
import { Route as OnboardingRouteImport } from './routes/onboarding/route'
import { Route as AdminRouteImport } from './routes/admin/route'
import { Route as IndexImport } from './routes/index'
import { Route as TestingIndexImport } from './routes/testing/index'
import { Route as PortalIndexImport } from './routes/portal/index'
import { Route as AdminIndexImport } from './routes/admin/index'
import { Route as TestingUsersImport } from './routes/testing/users'
import { Route as TestingSystemToolsImport } from './routes/testing/system-tools'
import { Route as ApplicationApplicationIdImport } from './routes/application/$applicationId'
import { Route as ApplicationStatusApplicationIdImport } from './routes/application-status/$applicationId'
import { Route as AdminOverviewImport } from './routes/admin/overview'
import { Route as ProviderProviderIdRouteImport } from './routes/provider.$providerId/route'
import { Route as PortalStartRouteImport } from './routes/portal/start/route'
import { Route as PortalDepositAccountRouteImport } from './routes/portal/deposit-account/route'
import { Route as AdminProvidersRouteImport } from './routes/admin/providers/route'
import { Route as AdminDepositAccountsRouteImport } from './routes/admin/deposit-accounts/route'
import { Route as AdminCustomersRouteImport } from './routes/admin/customers/route'
import { Route as AdminConfigurationRouteImport } from './routes/admin/configuration/route'
import { Route as AdminApplicationsRouteImport } from './routes/admin/applications/route'
import { Route as ProviderProviderIdIndexImport } from './routes/provider.$providerId/index'
import { Route as PortalDocumentsIndexImport } from './routes/portal/documents/index'
import { Route as PortalDepositAccountIndexImport } from './routes/portal/deposit-account/index'
import { Route as AdminProvidersIndexImport } from './routes/admin/providers/index'
import { Route as AdminDepositAccountsIndexImport } from './routes/admin/deposit-accounts/index'
import { Route as AdminCustomersIndexImport } from './routes/admin/customers/index'
import { Route as AdminConfigurationIndexImport } from './routes/admin/configuration/index'
import { Route as AdminApplicationsIndexImport } from './routes/admin/applications/index'
import { Route as ProviderProviderIdStartImport } from './routes/provider.$providerId/start'
import { Route as AdminProvidersAddImport } from './routes/admin/providers/add'
import { Route as AdminCustomersUserIdImport } from './routes/admin/customers/$userId'
import { Route as AdminApplicationsApplicationIdImport } from './routes/admin/applications/$applicationId'
import { Route as ProviderProviderIdToolsRouteImport } from './routes/provider.$providerId/tools/route'
import { Route as ProviderProviderIdTeamRouteImport } from './routes/provider.$providerId/team/route'
import { Route as ProviderProviderIdApplicationsRouteImport } from './routes/provider.$providerId/applications/route'
import { Route as PortalStartNewRouteImport } from './routes/portal/start/new/route'
import { Route as PortalStartGuideRouteImport } from './routes/portal/start/_guide/route'
import { Route as PortalDepositAccountDepositAccountIdRouteImport } from './routes/portal/deposit-account/$depositAccountId/route'
import { Route as AdminProvidersProviderIdRouteImport } from './routes/admin/providers/$providerId/route'
import { Route as AdminConfigurationTeamRouteImport } from './routes/admin/configuration/team/route'
import { Route as AdminConfigurationProxyRouteImport } from './routes/admin/configuration/proxy/route'
import { Route as AdminConfigurationOmnibusRouteImport } from './routes/admin/configuration/omnibus/route'
import { Route as AdminConfigurationFundingAccountsRouteImport } from './routes/admin/configuration/funding-accounts/route'
import { Route as AdminConfigurationApiKeysRouteImport } from './routes/admin/configuration/api-keys/route'
import { Route as ProviderProviderIdToolsIndexImport } from './routes/provider.$providerId/tools/index'
import { Route as ProviderProviderIdTeamIndexImport } from './routes/provider.$providerId/team/index'
import { Route as ProviderProviderIdApplicationsIndexImport } from './routes/provider.$providerId/applications/index'
import { Route as PortalStartNewIndexImport } from './routes/portal/start/new/index'
import { Route as PortalStartGuideIndexImport } from './routes/portal/start/_guide/index'
import { Route as PortalDepositAccountDepositAccountIdIndexImport } from './routes/portal/deposit-account/$depositAccountId/index'
import { Route as AdminProvidersProviderIdIndexImport } from './routes/admin/providers/$providerId/index'
import { Route as AdminConfigurationTeamIndexImport } from './routes/admin/configuration/team/index'
import { Route as AdminConfigurationProxyIndexImport } from './routes/admin/configuration/proxy/index'
import { Route as AdminConfigurationOmnibusIndexImport } from './routes/admin/configuration/omnibus/index'
import { Route as AdminConfigurationFundingAccountsIndexImport } from './routes/admin/configuration/funding-accounts/index'
import { Route as AdminConfigurationFeaturesIndexImport } from './routes/admin/configuration/features/index'
import { Route as ProviderProviderIdTeamUserIdImport } from './routes/provider.$providerId/team/$userId'
import { Route as ProviderProviderIdApplicationsApplicationIdImport } from './routes/provider.$providerId/applications/$applicationId'
import { Route as PortalStartNewDepositAccountTypeImport } from './routes/portal/start/new/$depositAccountType'
import { Route as PortalStartGuideRetirementCheckingImport } from './routes/portal/start/_guide/retirement-checking'
import { Route as AdminConfigurationTeamUserIdImport } from './routes/admin/configuration/team/$userId'
import { Route as AdminConfigurationProxyProxyIdImport } from './routes/admin/configuration/proxy/$proxyId'
import { Route as AdminConfigurationOmnibusOmnibusAccountIdImport } from './routes/admin/configuration/omnibus/$omnibusAccountId'
import { Route as PortalDepositAccountDepositAccountIdNewTransferRouteImport } from './routes/portal/deposit-account/$depositAccountId/new-transfer/route'
import { Route as PortalDepositAccountDepositAccountIdHomeRouteImport } from './routes/portal/deposit-account/$depositAccountId/home/route'
import { Route as PortalDepositAccountDepositAccountIdHomeIndexImport } from './routes/portal/deposit-account/$depositAccountId/home/index'
import { Route as ProviderProviderIdApplicationsStatusApplicationIdImport } from './routes/provider.$providerId/applications/status.$applicationId'
import { Route as PortalDepositAccountDepositAccountIdNewTransferWireImport } from './routes/portal/deposit-account/$depositAccountId/new-transfer/wire'
import { Route as PortalDepositAccountDepositAccountIdNewTransferAchImport } from './routes/portal/deposit-account/$depositAccountId/new-transfer/ach'
import { Route as PortalDepositAccountDepositAccountIdHomeWiresImport } from './routes/portal/deposit-account/$depositAccountId/home/wires'
import { Route as PortalDepositAccountDepositAccountIdHomeTransactionsImport } from './routes/portal/deposit-account/$depositAccountId/home/transactions'
import { Route as PortalDepositAccountDepositAccountIdHomeAchImport } from './routes/portal/deposit-account/$depositAccountId/home/ach'
import { Route as AdminProvidersProviderIdUsersUserIdRouteImport } from './routes/admin/providers/$providerId/users.$userId.route'

// Create/Update Routes

const WelcomeRoute = WelcomeImport.update({
  id: '/welcome',
  path: '/welcome',
  getParentRoute: () => rootRoute,
} as any)

const LogoutRoute = LogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const TestingRouteRoute = TestingRouteImport.update({
  id: '/testing',
  path: '/testing',
  getParentRoute: () => rootRoute,
} as any)

const StartRouteRoute = StartRouteImport.update({
  id: '/start',
  path: '/start',
  getParentRoute: () => rootRoute,
} as any)

const PortalRouteRoute = PortalRouteImport.update({
  id: '/portal',
  path: '/portal',
  getParentRoute: () => rootRoute,
} as any)

const OnboardingRouteRoute = OnboardingRouteImport.update({
  id: '/onboarding',
  path: '/onboarding',
  getParentRoute: () => rootRoute,
} as any)

const AdminRouteRoute = AdminRouteImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const TestingIndexRoute = TestingIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => TestingRouteRoute,
} as any)

const PortalIndexRoute = PortalIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PortalRouteRoute,
} as any)

const AdminIndexRoute = AdminIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AdminRouteRoute,
} as any)

const TestingUsersRoute = TestingUsersImport.update({
  id: '/users',
  path: '/users',
  getParentRoute: () => TestingRouteRoute,
} as any)

const TestingSystemToolsRoute = TestingSystemToolsImport.update({
  id: '/system-tools',
  path: '/system-tools',
  getParentRoute: () => TestingRouteRoute,
} as any)

const ApplicationApplicationIdRoute = ApplicationApplicationIdImport.update({
  id: '/application/$applicationId',
  path: '/application/$applicationId',
  getParentRoute: () => rootRoute,
} as any)

const ApplicationStatusApplicationIdRoute =
  ApplicationStatusApplicationIdImport.update({
    id: '/application-status/$applicationId',
    path: '/application-status/$applicationId',
    getParentRoute: () => rootRoute,
  } as any)

const AdminOverviewRoute = AdminOverviewImport.update({
  id: '/overview',
  path: '/overview',
  getParentRoute: () => AdminRouteRoute,
} as any)

const ProviderProviderIdRouteRoute = ProviderProviderIdRouteImport.update({
  id: '/provider/$providerId',
  path: '/provider/$providerId',
  getParentRoute: () => rootRoute,
} as any)

const PortalStartRouteRoute = PortalStartRouteImport.update({
  id: '/start',
  path: '/start',
  getParentRoute: () => PortalRouteRoute,
} as any)

const PortalDepositAccountRouteRoute = PortalDepositAccountRouteImport.update({
  id: '/deposit-account',
  path: '/deposit-account',
  getParentRoute: () => PortalRouteRoute,
} as any)

const AdminProvidersRouteRoute = AdminProvidersRouteImport.update({
  id: '/providers',
  path: '/providers',
  getParentRoute: () => AdminRouteRoute,
} as any)

const AdminDepositAccountsRouteRoute = AdminDepositAccountsRouteImport.update({
  id: '/deposit-accounts',
  path: '/deposit-accounts',
  getParentRoute: () => AdminRouteRoute,
} as any)

const AdminCustomersRouteRoute = AdminCustomersRouteImport.update({
  id: '/customers',
  path: '/customers',
  getParentRoute: () => AdminRouteRoute,
} as any)

const AdminConfigurationRouteRoute = AdminConfigurationRouteImport.update({
  id: '/configuration',
  path: '/configuration',
  getParentRoute: () => AdminRouteRoute,
} as any)

const AdminApplicationsRouteRoute = AdminApplicationsRouteImport.update({
  id: '/applications',
  path: '/applications',
  getParentRoute: () => AdminRouteRoute,
} as any)

const ProviderProviderIdIndexRoute = ProviderProviderIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ProviderProviderIdRouteRoute,
} as any)

const PortalDocumentsIndexRoute = PortalDocumentsIndexImport.update({
  id: '/documents/',
  path: '/documents/',
  getParentRoute: () => PortalRouteRoute,
} as any)

const PortalDepositAccountIndexRoute = PortalDepositAccountIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PortalDepositAccountRouteRoute,
} as any)

const AdminProvidersIndexRoute = AdminProvidersIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AdminProvidersRouteRoute,
} as any)

const AdminDepositAccountsIndexRoute = AdminDepositAccountsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AdminDepositAccountsRouteRoute,
} as any)

const AdminCustomersIndexRoute = AdminCustomersIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AdminCustomersRouteRoute,
} as any)

const AdminConfigurationIndexRoute = AdminConfigurationIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AdminConfigurationRouteRoute,
} as any)

const AdminApplicationsIndexRoute = AdminApplicationsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AdminApplicationsRouteRoute,
} as any)

const ProviderProviderIdStartRoute = ProviderProviderIdStartImport.update({
  id: '/start',
  path: '/start',
  getParentRoute: () => ProviderProviderIdRouteRoute,
} as any)

const AdminProvidersAddRoute = AdminProvidersAddImport.update({
  id: '/add',
  path: '/add',
  getParentRoute: () => AdminProvidersRouteRoute,
} as any)

const AdminCustomersUserIdRoute = AdminCustomersUserIdImport.update({
  id: '/$userId',
  path: '/$userId',
  getParentRoute: () => AdminCustomersRouteRoute,
} as any)

const AdminApplicationsApplicationIdRoute =
  AdminApplicationsApplicationIdImport.update({
    id: '/$applicationId',
    path: '/$applicationId',
    getParentRoute: () => AdminApplicationsRouteRoute,
  } as any)

const ProviderProviderIdToolsRouteRoute =
  ProviderProviderIdToolsRouteImport.update({
    id: '/tools',
    path: '/tools',
    getParentRoute: () => ProviderProviderIdRouteRoute,
  } as any)

const ProviderProviderIdTeamRouteRoute =
  ProviderProviderIdTeamRouteImport.update({
    id: '/team',
    path: '/team',
    getParentRoute: () => ProviderProviderIdRouteRoute,
  } as any)

const ProviderProviderIdApplicationsRouteRoute =
  ProviderProviderIdApplicationsRouteImport.update({
    id: '/applications',
    path: '/applications',
    getParentRoute: () => ProviderProviderIdRouteRoute,
  } as any)

const PortalStartNewRouteRoute = PortalStartNewRouteImport.update({
  id: '/new',
  path: '/new',
  getParentRoute: () => PortalStartRouteRoute,
} as any)

const PortalStartGuideRouteRoute = PortalStartGuideRouteImport.update({
  id: '/_guide',
  getParentRoute: () => PortalStartRouteRoute,
} as any)

const PortalDepositAccountDepositAccountIdRouteRoute =
  PortalDepositAccountDepositAccountIdRouteImport.update({
    id: '/$depositAccountId',
    path: '/$depositAccountId',
    getParentRoute: () => PortalDepositAccountRouteRoute,
  } as any)

const AdminProvidersProviderIdRouteRoute =
  AdminProvidersProviderIdRouteImport.update({
    id: '/$providerId',
    path: '/$providerId',
    getParentRoute: () => AdminProvidersRouteRoute,
  } as any)

const AdminConfigurationTeamRouteRoute =
  AdminConfigurationTeamRouteImport.update({
    id: '/team',
    path: '/team',
    getParentRoute: () => AdminConfigurationRouteRoute,
  } as any)

const AdminConfigurationProxyRouteRoute =
  AdminConfigurationProxyRouteImport.update({
    id: '/proxy',
    path: '/proxy',
    getParentRoute: () => AdminConfigurationRouteRoute,
  } as any)

const AdminConfigurationOmnibusRouteRoute =
  AdminConfigurationOmnibusRouteImport.update({
    id: '/omnibus',
    path: '/omnibus',
    getParentRoute: () => AdminConfigurationRouteRoute,
  } as any)

const AdminConfigurationFundingAccountsRouteRoute =
  AdminConfigurationFundingAccountsRouteImport.update({
    id: '/funding-accounts',
    path: '/funding-accounts',
    getParentRoute: () => AdminConfigurationRouteRoute,
  } as any)

const AdminConfigurationApiKeysRouteRoute =
  AdminConfigurationApiKeysRouteImport.update({
    id: '/api-keys',
    path: '/api-keys',
    getParentRoute: () => AdminConfigurationRouteRoute,
  } as any)

const ProviderProviderIdToolsIndexRoute =
  ProviderProviderIdToolsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ProviderProviderIdToolsRouteRoute,
  } as any)

const ProviderProviderIdTeamIndexRoute =
  ProviderProviderIdTeamIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ProviderProviderIdTeamRouteRoute,
  } as any)

const ProviderProviderIdApplicationsIndexRoute =
  ProviderProviderIdApplicationsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ProviderProviderIdApplicationsRouteRoute,
  } as any)

const PortalStartNewIndexRoute = PortalStartNewIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PortalStartNewRouteRoute,
} as any)

const PortalStartGuideIndexRoute = PortalStartGuideIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PortalStartGuideRouteRoute,
} as any)

const PortalDepositAccountDepositAccountIdIndexRoute =
  PortalDepositAccountDepositAccountIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PortalDepositAccountDepositAccountIdRouteRoute,
  } as any)

const AdminProvidersProviderIdIndexRoute =
  AdminProvidersProviderIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AdminProvidersProviderIdRouteRoute,
  } as any)

const AdminConfigurationTeamIndexRoute =
  AdminConfigurationTeamIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AdminConfigurationTeamRouteRoute,
  } as any)

const AdminConfigurationProxyIndexRoute =
  AdminConfigurationProxyIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AdminConfigurationProxyRouteRoute,
  } as any)

const AdminConfigurationOmnibusIndexRoute =
  AdminConfigurationOmnibusIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AdminConfigurationOmnibusRouteRoute,
  } as any)

const AdminConfigurationFundingAccountsIndexRoute =
  AdminConfigurationFundingAccountsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AdminConfigurationFundingAccountsRouteRoute,
  } as any)

const AdminConfigurationFeaturesIndexRoute =
  AdminConfigurationFeaturesIndexImport.update({
    id: '/features/',
    path: '/features/',
    getParentRoute: () => AdminConfigurationRouteRoute,
  } as any)

const ProviderProviderIdTeamUserIdRoute =
  ProviderProviderIdTeamUserIdImport.update({
    id: '/$userId',
    path: '/$userId',
    getParentRoute: () => ProviderProviderIdTeamRouteRoute,
  } as any)

const ProviderProviderIdApplicationsApplicationIdRoute =
  ProviderProviderIdApplicationsApplicationIdImport.update({
    id: '/$applicationId',
    path: '/$applicationId',
    getParentRoute: () => ProviderProviderIdApplicationsRouteRoute,
  } as any)

const PortalStartNewDepositAccountTypeRoute =
  PortalStartNewDepositAccountTypeImport.update({
    id: '/$depositAccountType',
    path: '/$depositAccountType',
    getParentRoute: () => PortalStartNewRouteRoute,
  } as any)

const PortalStartGuideRetirementCheckingRoute =
  PortalStartGuideRetirementCheckingImport.update({
    id: '/retirement-checking',
    path: '/retirement-checking',
    getParentRoute: () => PortalStartGuideRouteRoute,
  } as any)

const AdminConfigurationTeamUserIdRoute =
  AdminConfigurationTeamUserIdImport.update({
    id: '/$userId',
    path: '/$userId',
    getParentRoute: () => AdminConfigurationTeamRouteRoute,
  } as any)

const AdminConfigurationProxyProxyIdRoute =
  AdminConfigurationProxyProxyIdImport.update({
    id: '/$proxyId',
    path: '/$proxyId',
    getParentRoute: () => AdminConfigurationProxyRouteRoute,
  } as any)

const AdminConfigurationOmnibusOmnibusAccountIdRoute =
  AdminConfigurationOmnibusOmnibusAccountIdImport.update({
    id: '/$omnibusAccountId',
    path: '/$omnibusAccountId',
    getParentRoute: () => AdminConfigurationOmnibusRouteRoute,
  } as any)

const PortalDepositAccountDepositAccountIdNewTransferRouteRoute =
  PortalDepositAccountDepositAccountIdNewTransferRouteImport.update({
    id: '/new-transfer',
    path: '/new-transfer',
    getParentRoute: () => PortalDepositAccountDepositAccountIdRouteRoute,
  } as any)

const PortalDepositAccountDepositAccountIdHomeRouteRoute =
  PortalDepositAccountDepositAccountIdHomeRouteImport.update({
    id: '/home',
    path: '/home',
    getParentRoute: () => PortalDepositAccountDepositAccountIdRouteRoute,
  } as any)

const PortalDepositAccountDepositAccountIdHomeIndexRoute =
  PortalDepositAccountDepositAccountIdHomeIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PortalDepositAccountDepositAccountIdHomeRouteRoute,
  } as any)

const ProviderProviderIdApplicationsStatusApplicationIdRoute =
  ProviderProviderIdApplicationsStatusApplicationIdImport.update({
    id: '/status/$applicationId',
    path: '/status/$applicationId',
    getParentRoute: () => ProviderProviderIdApplicationsRouteRoute,
  } as any)

const PortalDepositAccountDepositAccountIdNewTransferWireRoute =
  PortalDepositAccountDepositAccountIdNewTransferWireImport.update({
    id: '/wire',
    path: '/wire',
    getParentRoute: () =>
      PortalDepositAccountDepositAccountIdNewTransferRouteRoute,
  } as any)

const PortalDepositAccountDepositAccountIdNewTransferAchRoute =
  PortalDepositAccountDepositAccountIdNewTransferAchImport.update({
    id: '/ach',
    path: '/ach',
    getParentRoute: () =>
      PortalDepositAccountDepositAccountIdNewTransferRouteRoute,
  } as any)

const PortalDepositAccountDepositAccountIdHomeWiresRoute =
  PortalDepositAccountDepositAccountIdHomeWiresImport.update({
    id: '/wires',
    path: '/wires',
    getParentRoute: () => PortalDepositAccountDepositAccountIdHomeRouteRoute,
  } as any)

const PortalDepositAccountDepositAccountIdHomeTransactionsRoute =
  PortalDepositAccountDepositAccountIdHomeTransactionsImport.update({
    id: '/transactions',
    path: '/transactions',
    getParentRoute: () => PortalDepositAccountDepositAccountIdHomeRouteRoute,
  } as any)

const PortalDepositAccountDepositAccountIdHomeAchRoute =
  PortalDepositAccountDepositAccountIdHomeAchImport.update({
    id: '/ach',
    path: '/ach',
    getParentRoute: () => PortalDepositAccountDepositAccountIdHomeRouteRoute,
  } as any)

const AdminProvidersProviderIdUsersUserIdRouteRoute =
  AdminProvidersProviderIdUsersUserIdRouteImport.update({
    id: '/users/$userId',
    path: '/users/$userId',
    getParentRoute: () => AdminProvidersProviderIdRouteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/admin': {
      id: '/admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AdminRouteImport
      parentRoute: typeof rootRoute
    }
    '/onboarding': {
      id: '/onboarding'
      path: '/onboarding'
      fullPath: '/onboarding'
      preLoaderRoute: typeof OnboardingRouteImport
      parentRoute: typeof rootRoute
    }
    '/portal': {
      id: '/portal'
      path: '/portal'
      fullPath: '/portal'
      preLoaderRoute: typeof PortalRouteImport
      parentRoute: typeof rootRoute
    }
    '/start': {
      id: '/start'
      path: '/start'
      fullPath: '/start'
      preLoaderRoute: typeof StartRouteImport
      parentRoute: typeof rootRoute
    }
    '/testing': {
      id: '/testing'
      path: '/testing'
      fullPath: '/testing'
      preLoaderRoute: typeof TestingRouteImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutImport
      parentRoute: typeof rootRoute
    }
    '/welcome': {
      id: '/welcome'
      path: '/welcome'
      fullPath: '/welcome'
      preLoaderRoute: typeof WelcomeImport
      parentRoute: typeof rootRoute
    }
    '/admin/applications': {
      id: '/admin/applications'
      path: '/applications'
      fullPath: '/admin/applications'
      preLoaderRoute: typeof AdminApplicationsRouteImport
      parentRoute: typeof AdminRouteImport
    }
    '/admin/configuration': {
      id: '/admin/configuration'
      path: '/configuration'
      fullPath: '/admin/configuration'
      preLoaderRoute: typeof AdminConfigurationRouteImport
      parentRoute: typeof AdminRouteImport
    }
    '/admin/customers': {
      id: '/admin/customers'
      path: '/customers'
      fullPath: '/admin/customers'
      preLoaderRoute: typeof AdminCustomersRouteImport
      parentRoute: typeof AdminRouteImport
    }
    '/admin/deposit-accounts': {
      id: '/admin/deposit-accounts'
      path: '/deposit-accounts'
      fullPath: '/admin/deposit-accounts'
      preLoaderRoute: typeof AdminDepositAccountsRouteImport
      parentRoute: typeof AdminRouteImport
    }
    '/admin/providers': {
      id: '/admin/providers'
      path: '/providers'
      fullPath: '/admin/providers'
      preLoaderRoute: typeof AdminProvidersRouteImport
      parentRoute: typeof AdminRouteImport
    }
    '/portal/deposit-account': {
      id: '/portal/deposit-account'
      path: '/deposit-account'
      fullPath: '/portal/deposit-account'
      preLoaderRoute: typeof PortalDepositAccountRouteImport
      parentRoute: typeof PortalRouteImport
    }
    '/portal/start': {
      id: '/portal/start'
      path: '/start'
      fullPath: '/portal/start'
      preLoaderRoute: typeof PortalStartRouteImport
      parentRoute: typeof PortalRouteImport
    }
    '/provider/$providerId': {
      id: '/provider/$providerId'
      path: '/provider/$providerId'
      fullPath: '/provider/$providerId'
      preLoaderRoute: typeof ProviderProviderIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/admin/overview': {
      id: '/admin/overview'
      path: '/overview'
      fullPath: '/admin/overview'
      preLoaderRoute: typeof AdminOverviewImport
      parentRoute: typeof AdminRouteImport
    }
    '/application-status/$applicationId': {
      id: '/application-status/$applicationId'
      path: '/application-status/$applicationId'
      fullPath: '/application-status/$applicationId'
      preLoaderRoute: typeof ApplicationStatusApplicationIdImport
      parentRoute: typeof rootRoute
    }
    '/application/$applicationId': {
      id: '/application/$applicationId'
      path: '/application/$applicationId'
      fullPath: '/application/$applicationId'
      preLoaderRoute: typeof ApplicationApplicationIdImport
      parentRoute: typeof rootRoute
    }
    '/testing/system-tools': {
      id: '/testing/system-tools'
      path: '/system-tools'
      fullPath: '/testing/system-tools'
      preLoaderRoute: typeof TestingSystemToolsImport
      parentRoute: typeof TestingRouteImport
    }
    '/testing/users': {
      id: '/testing/users'
      path: '/users'
      fullPath: '/testing/users'
      preLoaderRoute: typeof TestingUsersImport
      parentRoute: typeof TestingRouteImport
    }
    '/admin/': {
      id: '/admin/'
      path: '/'
      fullPath: '/admin/'
      preLoaderRoute: typeof AdminIndexImport
      parentRoute: typeof AdminRouteImport
    }
    '/portal/': {
      id: '/portal/'
      path: '/'
      fullPath: '/portal/'
      preLoaderRoute: typeof PortalIndexImport
      parentRoute: typeof PortalRouteImport
    }
    '/testing/': {
      id: '/testing/'
      path: '/'
      fullPath: '/testing/'
      preLoaderRoute: typeof TestingIndexImport
      parentRoute: typeof TestingRouteImport
    }
    '/admin/configuration/api-keys': {
      id: '/admin/configuration/api-keys'
      path: '/api-keys'
      fullPath: '/admin/configuration/api-keys'
      preLoaderRoute: typeof AdminConfigurationApiKeysRouteImport
      parentRoute: typeof AdminConfigurationRouteImport
    }
    '/admin/configuration/funding-accounts': {
      id: '/admin/configuration/funding-accounts'
      path: '/funding-accounts'
      fullPath: '/admin/configuration/funding-accounts'
      preLoaderRoute: typeof AdminConfigurationFundingAccountsRouteImport
      parentRoute: typeof AdminConfigurationRouteImport
    }
    '/admin/configuration/omnibus': {
      id: '/admin/configuration/omnibus'
      path: '/omnibus'
      fullPath: '/admin/configuration/omnibus'
      preLoaderRoute: typeof AdminConfigurationOmnibusRouteImport
      parentRoute: typeof AdminConfigurationRouteImport
    }
    '/admin/configuration/proxy': {
      id: '/admin/configuration/proxy'
      path: '/proxy'
      fullPath: '/admin/configuration/proxy'
      preLoaderRoute: typeof AdminConfigurationProxyRouteImport
      parentRoute: typeof AdminConfigurationRouteImport
    }
    '/admin/configuration/team': {
      id: '/admin/configuration/team'
      path: '/team'
      fullPath: '/admin/configuration/team'
      preLoaderRoute: typeof AdminConfigurationTeamRouteImport
      parentRoute: typeof AdminConfigurationRouteImport
    }
    '/admin/providers/$providerId': {
      id: '/admin/providers/$providerId'
      path: '/$providerId'
      fullPath: '/admin/providers/$providerId'
      preLoaderRoute: typeof AdminProvidersProviderIdRouteImport
      parentRoute: typeof AdminProvidersRouteImport
    }
    '/portal/deposit-account/$depositAccountId': {
      id: '/portal/deposit-account/$depositAccountId'
      path: '/$depositAccountId'
      fullPath: '/portal/deposit-account/$depositAccountId'
      preLoaderRoute: typeof PortalDepositAccountDepositAccountIdRouteImport
      parentRoute: typeof PortalDepositAccountRouteImport
    }
    '/portal/start/_guide': {
      id: '/portal/start/_guide'
      path: ''
      fullPath: '/portal/start'
      preLoaderRoute: typeof PortalStartGuideRouteImport
      parentRoute: typeof PortalStartRouteImport
    }
    '/portal/start/new': {
      id: '/portal/start/new'
      path: '/new'
      fullPath: '/portal/start/new'
      preLoaderRoute: typeof PortalStartNewRouteImport
      parentRoute: typeof PortalStartRouteImport
    }
    '/provider/$providerId/applications': {
      id: '/provider/$providerId/applications'
      path: '/applications'
      fullPath: '/provider/$providerId/applications'
      preLoaderRoute: typeof ProviderProviderIdApplicationsRouteImport
      parentRoute: typeof ProviderProviderIdRouteImport
    }
    '/provider/$providerId/team': {
      id: '/provider/$providerId/team'
      path: '/team'
      fullPath: '/provider/$providerId/team'
      preLoaderRoute: typeof ProviderProviderIdTeamRouteImport
      parentRoute: typeof ProviderProviderIdRouteImport
    }
    '/provider/$providerId/tools': {
      id: '/provider/$providerId/tools'
      path: '/tools'
      fullPath: '/provider/$providerId/tools'
      preLoaderRoute: typeof ProviderProviderIdToolsRouteImport
      parentRoute: typeof ProviderProviderIdRouteImport
    }
    '/admin/applications/$applicationId': {
      id: '/admin/applications/$applicationId'
      path: '/$applicationId'
      fullPath: '/admin/applications/$applicationId'
      preLoaderRoute: typeof AdminApplicationsApplicationIdImport
      parentRoute: typeof AdminApplicationsRouteImport
    }
    '/admin/customers/$userId': {
      id: '/admin/customers/$userId'
      path: '/$userId'
      fullPath: '/admin/customers/$userId'
      preLoaderRoute: typeof AdminCustomersUserIdImport
      parentRoute: typeof AdminCustomersRouteImport
    }
    '/admin/providers/add': {
      id: '/admin/providers/add'
      path: '/add'
      fullPath: '/admin/providers/add'
      preLoaderRoute: typeof AdminProvidersAddImport
      parentRoute: typeof AdminProvidersRouteImport
    }
    '/provider/$providerId/start': {
      id: '/provider/$providerId/start'
      path: '/start'
      fullPath: '/provider/$providerId/start'
      preLoaderRoute: typeof ProviderProviderIdStartImport
      parentRoute: typeof ProviderProviderIdRouteImport
    }
    '/admin/applications/': {
      id: '/admin/applications/'
      path: '/'
      fullPath: '/admin/applications/'
      preLoaderRoute: typeof AdminApplicationsIndexImport
      parentRoute: typeof AdminApplicationsRouteImport
    }
    '/admin/configuration/': {
      id: '/admin/configuration/'
      path: '/'
      fullPath: '/admin/configuration/'
      preLoaderRoute: typeof AdminConfigurationIndexImport
      parentRoute: typeof AdminConfigurationRouteImport
    }
    '/admin/customers/': {
      id: '/admin/customers/'
      path: '/'
      fullPath: '/admin/customers/'
      preLoaderRoute: typeof AdminCustomersIndexImport
      parentRoute: typeof AdminCustomersRouteImport
    }
    '/admin/deposit-accounts/': {
      id: '/admin/deposit-accounts/'
      path: '/'
      fullPath: '/admin/deposit-accounts/'
      preLoaderRoute: typeof AdminDepositAccountsIndexImport
      parentRoute: typeof AdminDepositAccountsRouteImport
    }
    '/admin/providers/': {
      id: '/admin/providers/'
      path: '/'
      fullPath: '/admin/providers/'
      preLoaderRoute: typeof AdminProvidersIndexImport
      parentRoute: typeof AdminProvidersRouteImport
    }
    '/portal/deposit-account/': {
      id: '/portal/deposit-account/'
      path: '/'
      fullPath: '/portal/deposit-account/'
      preLoaderRoute: typeof PortalDepositAccountIndexImport
      parentRoute: typeof PortalDepositAccountRouteImport
    }
    '/portal/documents/': {
      id: '/portal/documents/'
      path: '/documents'
      fullPath: '/portal/documents'
      preLoaderRoute: typeof PortalDocumentsIndexImport
      parentRoute: typeof PortalRouteImport
    }
    '/provider/$providerId/': {
      id: '/provider/$providerId/'
      path: '/'
      fullPath: '/provider/$providerId/'
      preLoaderRoute: typeof ProviderProviderIdIndexImport
      parentRoute: typeof ProviderProviderIdRouteImport
    }
    '/portal/deposit-account/$depositAccountId/home': {
      id: '/portal/deposit-account/$depositAccountId/home'
      path: '/home'
      fullPath: '/portal/deposit-account/$depositAccountId/home'
      preLoaderRoute: typeof PortalDepositAccountDepositAccountIdHomeRouteImport
      parentRoute: typeof PortalDepositAccountDepositAccountIdRouteImport
    }
    '/portal/deposit-account/$depositAccountId/new-transfer': {
      id: '/portal/deposit-account/$depositAccountId/new-transfer'
      path: '/new-transfer'
      fullPath: '/portal/deposit-account/$depositAccountId/new-transfer'
      preLoaderRoute: typeof PortalDepositAccountDepositAccountIdNewTransferRouteImport
      parentRoute: typeof PortalDepositAccountDepositAccountIdRouteImport
    }
    '/admin/configuration/omnibus/$omnibusAccountId': {
      id: '/admin/configuration/omnibus/$omnibusAccountId'
      path: '/$omnibusAccountId'
      fullPath: '/admin/configuration/omnibus/$omnibusAccountId'
      preLoaderRoute: typeof AdminConfigurationOmnibusOmnibusAccountIdImport
      parentRoute: typeof AdminConfigurationOmnibusRouteImport
    }
    '/admin/configuration/proxy/$proxyId': {
      id: '/admin/configuration/proxy/$proxyId'
      path: '/$proxyId'
      fullPath: '/admin/configuration/proxy/$proxyId'
      preLoaderRoute: typeof AdminConfigurationProxyProxyIdImport
      parentRoute: typeof AdminConfigurationProxyRouteImport
    }
    '/admin/configuration/team/$userId': {
      id: '/admin/configuration/team/$userId'
      path: '/$userId'
      fullPath: '/admin/configuration/team/$userId'
      preLoaderRoute: typeof AdminConfigurationTeamUserIdImport
      parentRoute: typeof AdminConfigurationTeamRouteImport
    }
    '/portal/start/_guide/retirement-checking': {
      id: '/portal/start/_guide/retirement-checking'
      path: '/retirement-checking'
      fullPath: '/portal/start/retirement-checking'
      preLoaderRoute: typeof PortalStartGuideRetirementCheckingImport
      parentRoute: typeof PortalStartGuideRouteImport
    }
    '/portal/start/new/$depositAccountType': {
      id: '/portal/start/new/$depositAccountType'
      path: '/$depositAccountType'
      fullPath: '/portal/start/new/$depositAccountType'
      preLoaderRoute: typeof PortalStartNewDepositAccountTypeImport
      parentRoute: typeof PortalStartNewRouteImport
    }
    '/provider/$providerId/applications/$applicationId': {
      id: '/provider/$providerId/applications/$applicationId'
      path: '/$applicationId'
      fullPath: '/provider/$providerId/applications/$applicationId'
      preLoaderRoute: typeof ProviderProviderIdApplicationsApplicationIdImport
      parentRoute: typeof ProviderProviderIdApplicationsRouteImport
    }
    '/provider/$providerId/team/$userId': {
      id: '/provider/$providerId/team/$userId'
      path: '/$userId'
      fullPath: '/provider/$providerId/team/$userId'
      preLoaderRoute: typeof ProviderProviderIdTeamUserIdImport
      parentRoute: typeof ProviderProviderIdTeamRouteImport
    }
    '/admin/configuration/features/': {
      id: '/admin/configuration/features/'
      path: '/features'
      fullPath: '/admin/configuration/features'
      preLoaderRoute: typeof AdminConfigurationFeaturesIndexImport
      parentRoute: typeof AdminConfigurationRouteImport
    }
    '/admin/configuration/funding-accounts/': {
      id: '/admin/configuration/funding-accounts/'
      path: '/'
      fullPath: '/admin/configuration/funding-accounts/'
      preLoaderRoute: typeof AdminConfigurationFundingAccountsIndexImport
      parentRoute: typeof AdminConfigurationFundingAccountsRouteImport
    }
    '/admin/configuration/omnibus/': {
      id: '/admin/configuration/omnibus/'
      path: '/'
      fullPath: '/admin/configuration/omnibus/'
      preLoaderRoute: typeof AdminConfigurationOmnibusIndexImport
      parentRoute: typeof AdminConfigurationOmnibusRouteImport
    }
    '/admin/configuration/proxy/': {
      id: '/admin/configuration/proxy/'
      path: '/'
      fullPath: '/admin/configuration/proxy/'
      preLoaderRoute: typeof AdminConfigurationProxyIndexImport
      parentRoute: typeof AdminConfigurationProxyRouteImport
    }
    '/admin/configuration/team/': {
      id: '/admin/configuration/team/'
      path: '/'
      fullPath: '/admin/configuration/team/'
      preLoaderRoute: typeof AdminConfigurationTeamIndexImport
      parentRoute: typeof AdminConfigurationTeamRouteImport
    }
    '/admin/providers/$providerId/': {
      id: '/admin/providers/$providerId/'
      path: '/'
      fullPath: '/admin/providers/$providerId/'
      preLoaderRoute: typeof AdminProvidersProviderIdIndexImport
      parentRoute: typeof AdminProvidersProviderIdRouteImport
    }
    '/portal/deposit-account/$depositAccountId/': {
      id: '/portal/deposit-account/$depositAccountId/'
      path: '/'
      fullPath: '/portal/deposit-account/$depositAccountId/'
      preLoaderRoute: typeof PortalDepositAccountDepositAccountIdIndexImport
      parentRoute: typeof PortalDepositAccountDepositAccountIdRouteImport
    }
    '/portal/start/_guide/': {
      id: '/portal/start/_guide/'
      path: '/'
      fullPath: '/portal/start/'
      preLoaderRoute: typeof PortalStartGuideIndexImport
      parentRoute: typeof PortalStartGuideRouteImport
    }
    '/portal/start/new/': {
      id: '/portal/start/new/'
      path: '/'
      fullPath: '/portal/start/new/'
      preLoaderRoute: typeof PortalStartNewIndexImport
      parentRoute: typeof PortalStartNewRouteImport
    }
    '/provider/$providerId/applications/': {
      id: '/provider/$providerId/applications/'
      path: '/'
      fullPath: '/provider/$providerId/applications/'
      preLoaderRoute: typeof ProviderProviderIdApplicationsIndexImport
      parentRoute: typeof ProviderProviderIdApplicationsRouteImport
    }
    '/provider/$providerId/team/': {
      id: '/provider/$providerId/team/'
      path: '/'
      fullPath: '/provider/$providerId/team/'
      preLoaderRoute: typeof ProviderProviderIdTeamIndexImport
      parentRoute: typeof ProviderProviderIdTeamRouteImport
    }
    '/provider/$providerId/tools/': {
      id: '/provider/$providerId/tools/'
      path: '/'
      fullPath: '/provider/$providerId/tools/'
      preLoaderRoute: typeof ProviderProviderIdToolsIndexImport
      parentRoute: typeof ProviderProviderIdToolsRouteImport
    }
    '/admin/providers/$providerId/users/$userId': {
      id: '/admin/providers/$providerId/users/$userId'
      path: '/users/$userId'
      fullPath: '/admin/providers/$providerId/users/$userId'
      preLoaderRoute: typeof AdminProvidersProviderIdUsersUserIdRouteImport
      parentRoute: typeof AdminProvidersProviderIdRouteImport
    }
    '/portal/deposit-account/$depositAccountId/home/ach': {
      id: '/portal/deposit-account/$depositAccountId/home/ach'
      path: '/ach'
      fullPath: '/portal/deposit-account/$depositAccountId/home/ach'
      preLoaderRoute: typeof PortalDepositAccountDepositAccountIdHomeAchImport
      parentRoute: typeof PortalDepositAccountDepositAccountIdHomeRouteImport
    }
    '/portal/deposit-account/$depositAccountId/home/transactions': {
      id: '/portal/deposit-account/$depositAccountId/home/transactions'
      path: '/transactions'
      fullPath: '/portal/deposit-account/$depositAccountId/home/transactions'
      preLoaderRoute: typeof PortalDepositAccountDepositAccountIdHomeTransactionsImport
      parentRoute: typeof PortalDepositAccountDepositAccountIdHomeRouteImport
    }
    '/portal/deposit-account/$depositAccountId/home/wires': {
      id: '/portal/deposit-account/$depositAccountId/home/wires'
      path: '/wires'
      fullPath: '/portal/deposit-account/$depositAccountId/home/wires'
      preLoaderRoute: typeof PortalDepositAccountDepositAccountIdHomeWiresImport
      parentRoute: typeof PortalDepositAccountDepositAccountIdHomeRouteImport
    }
    '/portal/deposit-account/$depositAccountId/new-transfer/ach': {
      id: '/portal/deposit-account/$depositAccountId/new-transfer/ach'
      path: '/ach'
      fullPath: '/portal/deposit-account/$depositAccountId/new-transfer/ach'
      preLoaderRoute: typeof PortalDepositAccountDepositAccountIdNewTransferAchImport
      parentRoute: typeof PortalDepositAccountDepositAccountIdNewTransferRouteImport
    }
    '/portal/deposit-account/$depositAccountId/new-transfer/wire': {
      id: '/portal/deposit-account/$depositAccountId/new-transfer/wire'
      path: '/wire'
      fullPath: '/portal/deposit-account/$depositAccountId/new-transfer/wire'
      preLoaderRoute: typeof PortalDepositAccountDepositAccountIdNewTransferWireImport
      parentRoute: typeof PortalDepositAccountDepositAccountIdNewTransferRouteImport
    }
    '/provider/$providerId/applications/status/$applicationId': {
      id: '/provider/$providerId/applications/status/$applicationId'
      path: '/status/$applicationId'
      fullPath: '/provider/$providerId/applications/status/$applicationId'
      preLoaderRoute: typeof ProviderProviderIdApplicationsStatusApplicationIdImport
      parentRoute: typeof ProviderProviderIdApplicationsRouteImport
    }
    '/portal/deposit-account/$depositAccountId/home/': {
      id: '/portal/deposit-account/$depositAccountId/home/'
      path: '/'
      fullPath: '/portal/deposit-account/$depositAccountId/home/'
      preLoaderRoute: typeof PortalDepositAccountDepositAccountIdHomeIndexImport
      parentRoute: typeof PortalDepositAccountDepositAccountIdHomeRouteImport
    }
  }
}

// Create and export the route tree

interface AdminApplicationsRouteRouteChildren {
  AdminApplicationsApplicationIdRoute: typeof AdminApplicationsApplicationIdRoute
  AdminApplicationsIndexRoute: typeof AdminApplicationsIndexRoute
}

const AdminApplicationsRouteRouteChildren: AdminApplicationsRouteRouteChildren =
  {
    AdminApplicationsApplicationIdRoute: AdminApplicationsApplicationIdRoute,
    AdminApplicationsIndexRoute: AdminApplicationsIndexRoute,
  }

const AdminApplicationsRouteRouteWithChildren =
  AdminApplicationsRouteRoute._addFileChildren(
    AdminApplicationsRouteRouteChildren,
  )

interface AdminConfigurationFundingAccountsRouteRouteChildren {
  AdminConfigurationFundingAccountsIndexRoute: typeof AdminConfigurationFundingAccountsIndexRoute
}

const AdminConfigurationFundingAccountsRouteRouteChildren: AdminConfigurationFundingAccountsRouteRouteChildren =
  {
    AdminConfigurationFundingAccountsIndexRoute:
      AdminConfigurationFundingAccountsIndexRoute,
  }

const AdminConfigurationFundingAccountsRouteRouteWithChildren =
  AdminConfigurationFundingAccountsRouteRoute._addFileChildren(
    AdminConfigurationFundingAccountsRouteRouteChildren,
  )

interface AdminConfigurationOmnibusRouteRouteChildren {
  AdminConfigurationOmnibusOmnibusAccountIdRoute: typeof AdminConfigurationOmnibusOmnibusAccountIdRoute
  AdminConfigurationOmnibusIndexRoute: typeof AdminConfigurationOmnibusIndexRoute
}

const AdminConfigurationOmnibusRouteRouteChildren: AdminConfigurationOmnibusRouteRouteChildren =
  {
    AdminConfigurationOmnibusOmnibusAccountIdRoute:
      AdminConfigurationOmnibusOmnibusAccountIdRoute,
    AdminConfigurationOmnibusIndexRoute: AdminConfigurationOmnibusIndexRoute,
  }

const AdminConfigurationOmnibusRouteRouteWithChildren =
  AdminConfigurationOmnibusRouteRoute._addFileChildren(
    AdminConfigurationOmnibusRouteRouteChildren,
  )

interface AdminConfigurationProxyRouteRouteChildren {
  AdminConfigurationProxyProxyIdRoute: typeof AdminConfigurationProxyProxyIdRoute
  AdminConfigurationProxyIndexRoute: typeof AdminConfigurationProxyIndexRoute
}

const AdminConfigurationProxyRouteRouteChildren: AdminConfigurationProxyRouteRouteChildren =
  {
    AdminConfigurationProxyProxyIdRoute: AdminConfigurationProxyProxyIdRoute,
    AdminConfigurationProxyIndexRoute: AdminConfigurationProxyIndexRoute,
  }

const AdminConfigurationProxyRouteRouteWithChildren =
  AdminConfigurationProxyRouteRoute._addFileChildren(
    AdminConfigurationProxyRouteRouteChildren,
  )

interface AdminConfigurationTeamRouteRouteChildren {
  AdminConfigurationTeamUserIdRoute: typeof AdminConfigurationTeamUserIdRoute
  AdminConfigurationTeamIndexRoute: typeof AdminConfigurationTeamIndexRoute
}

const AdminConfigurationTeamRouteRouteChildren: AdminConfigurationTeamRouteRouteChildren =
  {
    AdminConfigurationTeamUserIdRoute: AdminConfigurationTeamUserIdRoute,
    AdminConfigurationTeamIndexRoute: AdminConfigurationTeamIndexRoute,
  }

const AdminConfigurationTeamRouteRouteWithChildren =
  AdminConfigurationTeamRouteRoute._addFileChildren(
    AdminConfigurationTeamRouteRouteChildren,
  )

interface AdminConfigurationRouteRouteChildren {
  AdminConfigurationApiKeysRouteRoute: typeof AdminConfigurationApiKeysRouteRoute
  AdminConfigurationFundingAccountsRouteRoute: typeof AdminConfigurationFundingAccountsRouteRouteWithChildren
  AdminConfigurationOmnibusRouteRoute: typeof AdminConfigurationOmnibusRouteRouteWithChildren
  AdminConfigurationProxyRouteRoute: typeof AdminConfigurationProxyRouteRouteWithChildren
  AdminConfigurationTeamRouteRoute: typeof AdminConfigurationTeamRouteRouteWithChildren
  AdminConfigurationIndexRoute: typeof AdminConfigurationIndexRoute
  AdminConfigurationFeaturesIndexRoute: typeof AdminConfigurationFeaturesIndexRoute
}

const AdminConfigurationRouteRouteChildren: AdminConfigurationRouteRouteChildren =
  {
    AdminConfigurationApiKeysRouteRoute: AdminConfigurationApiKeysRouteRoute,
    AdminConfigurationFundingAccountsRouteRoute:
      AdminConfigurationFundingAccountsRouteRouteWithChildren,
    AdminConfigurationOmnibusRouteRoute:
      AdminConfigurationOmnibusRouteRouteWithChildren,
    AdminConfigurationProxyRouteRoute:
      AdminConfigurationProxyRouteRouteWithChildren,
    AdminConfigurationTeamRouteRoute:
      AdminConfigurationTeamRouteRouteWithChildren,
    AdminConfigurationIndexRoute: AdminConfigurationIndexRoute,
    AdminConfigurationFeaturesIndexRoute: AdminConfigurationFeaturesIndexRoute,
  }

const AdminConfigurationRouteRouteWithChildren =
  AdminConfigurationRouteRoute._addFileChildren(
    AdminConfigurationRouteRouteChildren,
  )

interface AdminCustomersRouteRouteChildren {
  AdminCustomersUserIdRoute: typeof AdminCustomersUserIdRoute
  AdminCustomersIndexRoute: typeof AdminCustomersIndexRoute
}

const AdminCustomersRouteRouteChildren: AdminCustomersRouteRouteChildren = {
  AdminCustomersUserIdRoute: AdminCustomersUserIdRoute,
  AdminCustomersIndexRoute: AdminCustomersIndexRoute,
}

const AdminCustomersRouteRouteWithChildren =
  AdminCustomersRouteRoute._addFileChildren(AdminCustomersRouteRouteChildren)

interface AdminDepositAccountsRouteRouteChildren {
  AdminDepositAccountsIndexRoute: typeof AdminDepositAccountsIndexRoute
}

const AdminDepositAccountsRouteRouteChildren: AdminDepositAccountsRouteRouteChildren =
  {
    AdminDepositAccountsIndexRoute: AdminDepositAccountsIndexRoute,
  }

const AdminDepositAccountsRouteRouteWithChildren =
  AdminDepositAccountsRouteRoute._addFileChildren(
    AdminDepositAccountsRouteRouteChildren,
  )

interface AdminProvidersProviderIdRouteRouteChildren {
  AdminProvidersProviderIdIndexRoute: typeof AdminProvidersProviderIdIndexRoute
  AdminProvidersProviderIdUsersUserIdRouteRoute: typeof AdminProvidersProviderIdUsersUserIdRouteRoute
}

const AdminProvidersProviderIdRouteRouteChildren: AdminProvidersProviderIdRouteRouteChildren =
  {
    AdminProvidersProviderIdIndexRoute: AdminProvidersProviderIdIndexRoute,
    AdminProvidersProviderIdUsersUserIdRouteRoute:
      AdminProvidersProviderIdUsersUserIdRouteRoute,
  }

const AdminProvidersProviderIdRouteRouteWithChildren =
  AdminProvidersProviderIdRouteRoute._addFileChildren(
    AdminProvidersProviderIdRouteRouteChildren,
  )

interface AdminProvidersRouteRouteChildren {
  AdminProvidersProviderIdRouteRoute: typeof AdminProvidersProviderIdRouteRouteWithChildren
  AdminProvidersAddRoute: typeof AdminProvidersAddRoute
  AdminProvidersIndexRoute: typeof AdminProvidersIndexRoute
}

const AdminProvidersRouteRouteChildren: AdminProvidersRouteRouteChildren = {
  AdminProvidersProviderIdRouteRoute:
    AdminProvidersProviderIdRouteRouteWithChildren,
  AdminProvidersAddRoute: AdminProvidersAddRoute,
  AdminProvidersIndexRoute: AdminProvidersIndexRoute,
}

const AdminProvidersRouteRouteWithChildren =
  AdminProvidersRouteRoute._addFileChildren(AdminProvidersRouteRouteChildren)

interface AdminRouteRouteChildren {
  AdminApplicationsRouteRoute: typeof AdminApplicationsRouteRouteWithChildren
  AdminConfigurationRouteRoute: typeof AdminConfigurationRouteRouteWithChildren
  AdminCustomersRouteRoute: typeof AdminCustomersRouteRouteWithChildren
  AdminDepositAccountsRouteRoute: typeof AdminDepositAccountsRouteRouteWithChildren
  AdminProvidersRouteRoute: typeof AdminProvidersRouteRouteWithChildren
  AdminOverviewRoute: typeof AdminOverviewRoute
  AdminIndexRoute: typeof AdminIndexRoute
}

const AdminRouteRouteChildren: AdminRouteRouteChildren = {
  AdminApplicationsRouteRoute: AdminApplicationsRouteRouteWithChildren,
  AdminConfigurationRouteRoute: AdminConfigurationRouteRouteWithChildren,
  AdminCustomersRouteRoute: AdminCustomersRouteRouteWithChildren,
  AdminDepositAccountsRouteRoute: AdminDepositAccountsRouteRouteWithChildren,
  AdminProvidersRouteRoute: AdminProvidersRouteRouteWithChildren,
  AdminOverviewRoute: AdminOverviewRoute,
  AdminIndexRoute: AdminIndexRoute,
}

const AdminRouteRouteWithChildren = AdminRouteRoute._addFileChildren(
  AdminRouteRouteChildren,
)

interface PortalDepositAccountDepositAccountIdHomeRouteRouteChildren {
  PortalDepositAccountDepositAccountIdHomeAchRoute: typeof PortalDepositAccountDepositAccountIdHomeAchRoute
  PortalDepositAccountDepositAccountIdHomeTransactionsRoute: typeof PortalDepositAccountDepositAccountIdHomeTransactionsRoute
  PortalDepositAccountDepositAccountIdHomeWiresRoute: typeof PortalDepositAccountDepositAccountIdHomeWiresRoute
  PortalDepositAccountDepositAccountIdHomeIndexRoute: typeof PortalDepositAccountDepositAccountIdHomeIndexRoute
}

const PortalDepositAccountDepositAccountIdHomeRouteRouteChildren: PortalDepositAccountDepositAccountIdHomeRouteRouteChildren =
  {
    PortalDepositAccountDepositAccountIdHomeAchRoute:
      PortalDepositAccountDepositAccountIdHomeAchRoute,
    PortalDepositAccountDepositAccountIdHomeTransactionsRoute:
      PortalDepositAccountDepositAccountIdHomeTransactionsRoute,
    PortalDepositAccountDepositAccountIdHomeWiresRoute:
      PortalDepositAccountDepositAccountIdHomeWiresRoute,
    PortalDepositAccountDepositAccountIdHomeIndexRoute:
      PortalDepositAccountDepositAccountIdHomeIndexRoute,
  }

const PortalDepositAccountDepositAccountIdHomeRouteRouteWithChildren =
  PortalDepositAccountDepositAccountIdHomeRouteRoute._addFileChildren(
    PortalDepositAccountDepositAccountIdHomeRouteRouteChildren,
  )

interface PortalDepositAccountDepositAccountIdNewTransferRouteRouteChildren {
  PortalDepositAccountDepositAccountIdNewTransferAchRoute: typeof PortalDepositAccountDepositAccountIdNewTransferAchRoute
  PortalDepositAccountDepositAccountIdNewTransferWireRoute: typeof PortalDepositAccountDepositAccountIdNewTransferWireRoute
}

const PortalDepositAccountDepositAccountIdNewTransferRouteRouteChildren: PortalDepositAccountDepositAccountIdNewTransferRouteRouteChildren =
  {
    PortalDepositAccountDepositAccountIdNewTransferAchRoute:
      PortalDepositAccountDepositAccountIdNewTransferAchRoute,
    PortalDepositAccountDepositAccountIdNewTransferWireRoute:
      PortalDepositAccountDepositAccountIdNewTransferWireRoute,
  }

const PortalDepositAccountDepositAccountIdNewTransferRouteRouteWithChildren =
  PortalDepositAccountDepositAccountIdNewTransferRouteRoute._addFileChildren(
    PortalDepositAccountDepositAccountIdNewTransferRouteRouteChildren,
  )

interface PortalDepositAccountDepositAccountIdRouteRouteChildren {
  PortalDepositAccountDepositAccountIdHomeRouteRoute: typeof PortalDepositAccountDepositAccountIdHomeRouteRouteWithChildren
  PortalDepositAccountDepositAccountIdNewTransferRouteRoute: typeof PortalDepositAccountDepositAccountIdNewTransferRouteRouteWithChildren
  PortalDepositAccountDepositAccountIdIndexRoute: typeof PortalDepositAccountDepositAccountIdIndexRoute
}

const PortalDepositAccountDepositAccountIdRouteRouteChildren: PortalDepositAccountDepositAccountIdRouteRouteChildren =
  {
    PortalDepositAccountDepositAccountIdHomeRouteRoute:
      PortalDepositAccountDepositAccountIdHomeRouteRouteWithChildren,
    PortalDepositAccountDepositAccountIdNewTransferRouteRoute:
      PortalDepositAccountDepositAccountIdNewTransferRouteRouteWithChildren,
    PortalDepositAccountDepositAccountIdIndexRoute:
      PortalDepositAccountDepositAccountIdIndexRoute,
  }

const PortalDepositAccountDepositAccountIdRouteRouteWithChildren =
  PortalDepositAccountDepositAccountIdRouteRoute._addFileChildren(
    PortalDepositAccountDepositAccountIdRouteRouteChildren,
  )

interface PortalDepositAccountRouteRouteChildren {
  PortalDepositAccountDepositAccountIdRouteRoute: typeof PortalDepositAccountDepositAccountIdRouteRouteWithChildren
  PortalDepositAccountIndexRoute: typeof PortalDepositAccountIndexRoute
}

const PortalDepositAccountRouteRouteChildren: PortalDepositAccountRouteRouteChildren =
  {
    PortalDepositAccountDepositAccountIdRouteRoute:
      PortalDepositAccountDepositAccountIdRouteRouteWithChildren,
    PortalDepositAccountIndexRoute: PortalDepositAccountIndexRoute,
  }

const PortalDepositAccountRouteRouteWithChildren =
  PortalDepositAccountRouteRoute._addFileChildren(
    PortalDepositAccountRouteRouteChildren,
  )

interface PortalStartGuideRouteRouteChildren {
  PortalStartGuideRetirementCheckingRoute: typeof PortalStartGuideRetirementCheckingRoute
  PortalStartGuideIndexRoute: typeof PortalStartGuideIndexRoute
}

const PortalStartGuideRouteRouteChildren: PortalStartGuideRouteRouteChildren = {
  PortalStartGuideRetirementCheckingRoute:
    PortalStartGuideRetirementCheckingRoute,
  PortalStartGuideIndexRoute: PortalStartGuideIndexRoute,
}

const PortalStartGuideRouteRouteWithChildren =
  PortalStartGuideRouteRoute._addFileChildren(
    PortalStartGuideRouteRouteChildren,
  )

interface PortalStartNewRouteRouteChildren {
  PortalStartNewDepositAccountTypeRoute: typeof PortalStartNewDepositAccountTypeRoute
  PortalStartNewIndexRoute: typeof PortalStartNewIndexRoute
}

const PortalStartNewRouteRouteChildren: PortalStartNewRouteRouteChildren = {
  PortalStartNewDepositAccountTypeRoute: PortalStartNewDepositAccountTypeRoute,
  PortalStartNewIndexRoute: PortalStartNewIndexRoute,
}

const PortalStartNewRouteRouteWithChildren =
  PortalStartNewRouteRoute._addFileChildren(PortalStartNewRouteRouteChildren)

interface PortalStartRouteRouteChildren {
  PortalStartGuideRouteRoute: typeof PortalStartGuideRouteRouteWithChildren
  PortalStartNewRouteRoute: typeof PortalStartNewRouteRouteWithChildren
}

const PortalStartRouteRouteChildren: PortalStartRouteRouteChildren = {
  PortalStartGuideRouteRoute: PortalStartGuideRouteRouteWithChildren,
  PortalStartNewRouteRoute: PortalStartNewRouteRouteWithChildren,
}

const PortalStartRouteRouteWithChildren =
  PortalStartRouteRoute._addFileChildren(PortalStartRouteRouteChildren)

interface PortalRouteRouteChildren {
  PortalDepositAccountRouteRoute: typeof PortalDepositAccountRouteRouteWithChildren
  PortalStartRouteRoute: typeof PortalStartRouteRouteWithChildren
  PortalIndexRoute: typeof PortalIndexRoute
  PortalDocumentsIndexRoute: typeof PortalDocumentsIndexRoute
}

const PortalRouteRouteChildren: PortalRouteRouteChildren = {
  PortalDepositAccountRouteRoute: PortalDepositAccountRouteRouteWithChildren,
  PortalStartRouteRoute: PortalStartRouteRouteWithChildren,
  PortalIndexRoute: PortalIndexRoute,
  PortalDocumentsIndexRoute: PortalDocumentsIndexRoute,
}

const PortalRouteRouteWithChildren = PortalRouteRoute._addFileChildren(
  PortalRouteRouteChildren,
)

interface TestingRouteRouteChildren {
  TestingSystemToolsRoute: typeof TestingSystemToolsRoute
  TestingUsersRoute: typeof TestingUsersRoute
  TestingIndexRoute: typeof TestingIndexRoute
}

const TestingRouteRouteChildren: TestingRouteRouteChildren = {
  TestingSystemToolsRoute: TestingSystemToolsRoute,
  TestingUsersRoute: TestingUsersRoute,
  TestingIndexRoute: TestingIndexRoute,
}

const TestingRouteRouteWithChildren = TestingRouteRoute._addFileChildren(
  TestingRouteRouteChildren,
)

interface ProviderProviderIdApplicationsRouteRouteChildren {
  ProviderProviderIdApplicationsApplicationIdRoute: typeof ProviderProviderIdApplicationsApplicationIdRoute
  ProviderProviderIdApplicationsIndexRoute: typeof ProviderProviderIdApplicationsIndexRoute
  ProviderProviderIdApplicationsStatusApplicationIdRoute: typeof ProviderProviderIdApplicationsStatusApplicationIdRoute
}

const ProviderProviderIdApplicationsRouteRouteChildren: ProviderProviderIdApplicationsRouteRouteChildren =
  {
    ProviderProviderIdApplicationsApplicationIdRoute:
      ProviderProviderIdApplicationsApplicationIdRoute,
    ProviderProviderIdApplicationsIndexRoute:
      ProviderProviderIdApplicationsIndexRoute,
    ProviderProviderIdApplicationsStatusApplicationIdRoute:
      ProviderProviderIdApplicationsStatusApplicationIdRoute,
  }

const ProviderProviderIdApplicationsRouteRouteWithChildren =
  ProviderProviderIdApplicationsRouteRoute._addFileChildren(
    ProviderProviderIdApplicationsRouteRouteChildren,
  )

interface ProviderProviderIdTeamRouteRouteChildren {
  ProviderProviderIdTeamUserIdRoute: typeof ProviderProviderIdTeamUserIdRoute
  ProviderProviderIdTeamIndexRoute: typeof ProviderProviderIdTeamIndexRoute
}

const ProviderProviderIdTeamRouteRouteChildren: ProviderProviderIdTeamRouteRouteChildren =
  {
    ProviderProviderIdTeamUserIdRoute: ProviderProviderIdTeamUserIdRoute,
    ProviderProviderIdTeamIndexRoute: ProviderProviderIdTeamIndexRoute,
  }

const ProviderProviderIdTeamRouteRouteWithChildren =
  ProviderProviderIdTeamRouteRoute._addFileChildren(
    ProviderProviderIdTeamRouteRouteChildren,
  )

interface ProviderProviderIdToolsRouteRouteChildren {
  ProviderProviderIdToolsIndexRoute: typeof ProviderProviderIdToolsIndexRoute
}

const ProviderProviderIdToolsRouteRouteChildren: ProviderProviderIdToolsRouteRouteChildren =
  {
    ProviderProviderIdToolsIndexRoute: ProviderProviderIdToolsIndexRoute,
  }

const ProviderProviderIdToolsRouteRouteWithChildren =
  ProviderProviderIdToolsRouteRoute._addFileChildren(
    ProviderProviderIdToolsRouteRouteChildren,
  )

interface ProviderProviderIdRouteRouteChildren {
  ProviderProviderIdApplicationsRouteRoute: typeof ProviderProviderIdApplicationsRouteRouteWithChildren
  ProviderProviderIdTeamRouteRoute: typeof ProviderProviderIdTeamRouteRouteWithChildren
  ProviderProviderIdToolsRouteRoute: typeof ProviderProviderIdToolsRouteRouteWithChildren
  ProviderProviderIdStartRoute: typeof ProviderProviderIdStartRoute
  ProviderProviderIdIndexRoute: typeof ProviderProviderIdIndexRoute
}

const ProviderProviderIdRouteRouteChildren: ProviderProviderIdRouteRouteChildren =
  {
    ProviderProviderIdApplicationsRouteRoute:
      ProviderProviderIdApplicationsRouteRouteWithChildren,
    ProviderProviderIdTeamRouteRoute:
      ProviderProviderIdTeamRouteRouteWithChildren,
    ProviderProviderIdToolsRouteRoute:
      ProviderProviderIdToolsRouteRouteWithChildren,
    ProviderProviderIdStartRoute: ProviderProviderIdStartRoute,
    ProviderProviderIdIndexRoute: ProviderProviderIdIndexRoute,
  }

const ProviderProviderIdRouteRouteWithChildren =
  ProviderProviderIdRouteRoute._addFileChildren(
    ProviderProviderIdRouteRouteChildren,
  )

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/admin': typeof AdminRouteRouteWithChildren
  '/onboarding': typeof OnboardingRouteRoute
  '/portal': typeof PortalRouteRouteWithChildren
  '/start': typeof StartRouteRoute
  '/testing': typeof TestingRouteRouteWithChildren
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/welcome': typeof WelcomeRoute
  '/admin/applications': typeof AdminApplicationsRouteRouteWithChildren
  '/admin/configuration': typeof AdminConfigurationRouteRouteWithChildren
  '/admin/customers': typeof AdminCustomersRouteRouteWithChildren
  '/admin/deposit-accounts': typeof AdminDepositAccountsRouteRouteWithChildren
  '/admin/providers': typeof AdminProvidersRouteRouteWithChildren
  '/portal/deposit-account': typeof PortalDepositAccountRouteRouteWithChildren
  '/portal/start': typeof PortalStartGuideRouteRouteWithChildren
  '/provider/$providerId': typeof ProviderProviderIdRouteRouteWithChildren
  '/admin/overview': typeof AdminOverviewRoute
  '/application-status/$applicationId': typeof ApplicationStatusApplicationIdRoute
  '/application/$applicationId': typeof ApplicationApplicationIdRoute
  '/testing/system-tools': typeof TestingSystemToolsRoute
  '/testing/users': typeof TestingUsersRoute
  '/admin/': typeof AdminIndexRoute
  '/portal/': typeof PortalIndexRoute
  '/testing/': typeof TestingIndexRoute
  '/admin/configuration/api-keys': typeof AdminConfigurationApiKeysRouteRoute
  '/admin/configuration/funding-accounts': typeof AdminConfigurationFundingAccountsRouteRouteWithChildren
  '/admin/configuration/omnibus': typeof AdminConfigurationOmnibusRouteRouteWithChildren
  '/admin/configuration/proxy': typeof AdminConfigurationProxyRouteRouteWithChildren
  '/admin/configuration/team': typeof AdminConfigurationTeamRouteRouteWithChildren
  '/admin/providers/$providerId': typeof AdminProvidersProviderIdRouteRouteWithChildren
  '/portal/deposit-account/$depositAccountId': typeof PortalDepositAccountDepositAccountIdRouteRouteWithChildren
  '/portal/start/new': typeof PortalStartNewRouteRouteWithChildren
  '/provider/$providerId/applications': typeof ProviderProviderIdApplicationsRouteRouteWithChildren
  '/provider/$providerId/team': typeof ProviderProviderIdTeamRouteRouteWithChildren
  '/provider/$providerId/tools': typeof ProviderProviderIdToolsRouteRouteWithChildren
  '/admin/applications/$applicationId': typeof AdminApplicationsApplicationIdRoute
  '/admin/customers/$userId': typeof AdminCustomersUserIdRoute
  '/admin/providers/add': typeof AdminProvidersAddRoute
  '/provider/$providerId/start': typeof ProviderProviderIdStartRoute
  '/admin/applications/': typeof AdminApplicationsIndexRoute
  '/admin/configuration/': typeof AdminConfigurationIndexRoute
  '/admin/customers/': typeof AdminCustomersIndexRoute
  '/admin/deposit-accounts/': typeof AdminDepositAccountsIndexRoute
  '/admin/providers/': typeof AdminProvidersIndexRoute
  '/portal/deposit-account/': typeof PortalDepositAccountIndexRoute
  '/portal/documents': typeof PortalDocumentsIndexRoute
  '/provider/$providerId/': typeof ProviderProviderIdIndexRoute
  '/portal/deposit-account/$depositAccountId/home': typeof PortalDepositAccountDepositAccountIdHomeRouteRouteWithChildren
  '/portal/deposit-account/$depositAccountId/new-transfer': typeof PortalDepositAccountDepositAccountIdNewTransferRouteRouteWithChildren
  '/admin/configuration/omnibus/$omnibusAccountId': typeof AdminConfigurationOmnibusOmnibusAccountIdRoute
  '/admin/configuration/proxy/$proxyId': typeof AdminConfigurationProxyProxyIdRoute
  '/admin/configuration/team/$userId': typeof AdminConfigurationTeamUserIdRoute
  '/portal/start/retirement-checking': typeof PortalStartGuideRetirementCheckingRoute
  '/portal/start/new/$depositAccountType': typeof PortalStartNewDepositAccountTypeRoute
  '/provider/$providerId/applications/$applicationId': typeof ProviderProviderIdApplicationsApplicationIdRoute
  '/provider/$providerId/team/$userId': typeof ProviderProviderIdTeamUserIdRoute
  '/admin/configuration/features': typeof AdminConfigurationFeaturesIndexRoute
  '/admin/configuration/funding-accounts/': typeof AdminConfigurationFundingAccountsIndexRoute
  '/admin/configuration/omnibus/': typeof AdminConfigurationOmnibusIndexRoute
  '/admin/configuration/proxy/': typeof AdminConfigurationProxyIndexRoute
  '/admin/configuration/team/': typeof AdminConfigurationTeamIndexRoute
  '/admin/providers/$providerId/': typeof AdminProvidersProviderIdIndexRoute
  '/portal/deposit-account/$depositAccountId/': typeof PortalDepositAccountDepositAccountIdIndexRoute
  '/portal/start/': typeof PortalStartGuideIndexRoute
  '/portal/start/new/': typeof PortalStartNewIndexRoute
  '/provider/$providerId/applications/': typeof ProviderProviderIdApplicationsIndexRoute
  '/provider/$providerId/team/': typeof ProviderProviderIdTeamIndexRoute
  '/provider/$providerId/tools/': typeof ProviderProviderIdToolsIndexRoute
  '/admin/providers/$providerId/users/$userId': typeof AdminProvidersProviderIdUsersUserIdRouteRoute
  '/portal/deposit-account/$depositAccountId/home/ach': typeof PortalDepositAccountDepositAccountIdHomeAchRoute
  '/portal/deposit-account/$depositAccountId/home/transactions': typeof PortalDepositAccountDepositAccountIdHomeTransactionsRoute
  '/portal/deposit-account/$depositAccountId/home/wires': typeof PortalDepositAccountDepositAccountIdHomeWiresRoute
  '/portal/deposit-account/$depositAccountId/new-transfer/ach': typeof PortalDepositAccountDepositAccountIdNewTransferAchRoute
  '/portal/deposit-account/$depositAccountId/new-transfer/wire': typeof PortalDepositAccountDepositAccountIdNewTransferWireRoute
  '/provider/$providerId/applications/status/$applicationId': typeof ProviderProviderIdApplicationsStatusApplicationIdRoute
  '/portal/deposit-account/$depositAccountId/home/': typeof PortalDepositAccountDepositAccountIdHomeIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/onboarding': typeof OnboardingRouteRoute
  '/start': typeof StartRouteRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/welcome': typeof WelcomeRoute
  '/portal/start': typeof PortalStartGuideIndexRoute
  '/admin/overview': typeof AdminOverviewRoute
  '/application-status/$applicationId': typeof ApplicationStatusApplicationIdRoute
  '/application/$applicationId': typeof ApplicationApplicationIdRoute
  '/testing/system-tools': typeof TestingSystemToolsRoute
  '/testing/users': typeof TestingUsersRoute
  '/admin': typeof AdminIndexRoute
  '/portal': typeof PortalIndexRoute
  '/testing': typeof TestingIndexRoute
  '/admin/configuration/api-keys': typeof AdminConfigurationApiKeysRouteRoute
  '/admin/applications/$applicationId': typeof AdminApplicationsApplicationIdRoute
  '/admin/customers/$userId': typeof AdminCustomersUserIdRoute
  '/admin/providers/add': typeof AdminProvidersAddRoute
  '/provider/$providerId/start': typeof ProviderProviderIdStartRoute
  '/admin/applications': typeof AdminApplicationsIndexRoute
  '/admin/configuration': typeof AdminConfigurationIndexRoute
  '/admin/customers': typeof AdminCustomersIndexRoute
  '/admin/deposit-accounts': typeof AdminDepositAccountsIndexRoute
  '/admin/providers': typeof AdminProvidersIndexRoute
  '/portal/deposit-account': typeof PortalDepositAccountIndexRoute
  '/portal/documents': typeof PortalDocumentsIndexRoute
  '/provider/$providerId': typeof ProviderProviderIdIndexRoute
  '/portal/deposit-account/$depositAccountId/new-transfer': typeof PortalDepositAccountDepositAccountIdNewTransferRouteRouteWithChildren
  '/admin/configuration/omnibus/$omnibusAccountId': typeof AdminConfigurationOmnibusOmnibusAccountIdRoute
  '/admin/configuration/proxy/$proxyId': typeof AdminConfigurationProxyProxyIdRoute
  '/admin/configuration/team/$userId': typeof AdminConfigurationTeamUserIdRoute
  '/portal/start/retirement-checking': typeof PortalStartGuideRetirementCheckingRoute
  '/portal/start/new/$depositAccountType': typeof PortalStartNewDepositAccountTypeRoute
  '/provider/$providerId/applications/$applicationId': typeof ProviderProviderIdApplicationsApplicationIdRoute
  '/provider/$providerId/team/$userId': typeof ProviderProviderIdTeamUserIdRoute
  '/admin/configuration/features': typeof AdminConfigurationFeaturesIndexRoute
  '/admin/configuration/funding-accounts': typeof AdminConfigurationFundingAccountsIndexRoute
  '/admin/configuration/omnibus': typeof AdminConfigurationOmnibusIndexRoute
  '/admin/configuration/proxy': typeof AdminConfigurationProxyIndexRoute
  '/admin/configuration/team': typeof AdminConfigurationTeamIndexRoute
  '/admin/providers/$providerId': typeof AdminProvidersProviderIdIndexRoute
  '/portal/deposit-account/$depositAccountId': typeof PortalDepositAccountDepositAccountIdIndexRoute
  '/portal/start/new': typeof PortalStartNewIndexRoute
  '/provider/$providerId/applications': typeof ProviderProviderIdApplicationsIndexRoute
  '/provider/$providerId/team': typeof ProviderProviderIdTeamIndexRoute
  '/provider/$providerId/tools': typeof ProviderProviderIdToolsIndexRoute
  '/admin/providers/$providerId/users/$userId': typeof AdminProvidersProviderIdUsersUserIdRouteRoute
  '/portal/deposit-account/$depositAccountId/home/ach': typeof PortalDepositAccountDepositAccountIdHomeAchRoute
  '/portal/deposit-account/$depositAccountId/home/transactions': typeof PortalDepositAccountDepositAccountIdHomeTransactionsRoute
  '/portal/deposit-account/$depositAccountId/home/wires': typeof PortalDepositAccountDepositAccountIdHomeWiresRoute
  '/portal/deposit-account/$depositAccountId/new-transfer/ach': typeof PortalDepositAccountDepositAccountIdNewTransferAchRoute
  '/portal/deposit-account/$depositAccountId/new-transfer/wire': typeof PortalDepositAccountDepositAccountIdNewTransferWireRoute
  '/provider/$providerId/applications/status/$applicationId': typeof ProviderProviderIdApplicationsStatusApplicationIdRoute
  '/portal/deposit-account/$depositAccountId/home': typeof PortalDepositAccountDepositAccountIdHomeIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/admin': typeof AdminRouteRouteWithChildren
  '/onboarding': typeof OnboardingRouteRoute
  '/portal': typeof PortalRouteRouteWithChildren
  '/start': typeof StartRouteRoute
  '/testing': typeof TestingRouteRouteWithChildren
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/welcome': typeof WelcomeRoute
  '/admin/applications': typeof AdminApplicationsRouteRouteWithChildren
  '/admin/configuration': typeof AdminConfigurationRouteRouteWithChildren
  '/admin/customers': typeof AdminCustomersRouteRouteWithChildren
  '/admin/deposit-accounts': typeof AdminDepositAccountsRouteRouteWithChildren
  '/admin/providers': typeof AdminProvidersRouteRouteWithChildren
  '/portal/deposit-account': typeof PortalDepositAccountRouteRouteWithChildren
  '/portal/start': typeof PortalStartRouteRouteWithChildren
  '/provider/$providerId': typeof ProviderProviderIdRouteRouteWithChildren
  '/admin/overview': typeof AdminOverviewRoute
  '/application-status/$applicationId': typeof ApplicationStatusApplicationIdRoute
  '/application/$applicationId': typeof ApplicationApplicationIdRoute
  '/testing/system-tools': typeof TestingSystemToolsRoute
  '/testing/users': typeof TestingUsersRoute
  '/admin/': typeof AdminIndexRoute
  '/portal/': typeof PortalIndexRoute
  '/testing/': typeof TestingIndexRoute
  '/admin/configuration/api-keys': typeof AdminConfigurationApiKeysRouteRoute
  '/admin/configuration/funding-accounts': typeof AdminConfigurationFundingAccountsRouteRouteWithChildren
  '/admin/configuration/omnibus': typeof AdminConfigurationOmnibusRouteRouteWithChildren
  '/admin/configuration/proxy': typeof AdminConfigurationProxyRouteRouteWithChildren
  '/admin/configuration/team': typeof AdminConfigurationTeamRouteRouteWithChildren
  '/admin/providers/$providerId': typeof AdminProvidersProviderIdRouteRouteWithChildren
  '/portal/deposit-account/$depositAccountId': typeof PortalDepositAccountDepositAccountIdRouteRouteWithChildren
  '/portal/start/_guide': typeof PortalStartGuideRouteRouteWithChildren
  '/portal/start/new': typeof PortalStartNewRouteRouteWithChildren
  '/provider/$providerId/applications': typeof ProviderProviderIdApplicationsRouteRouteWithChildren
  '/provider/$providerId/team': typeof ProviderProviderIdTeamRouteRouteWithChildren
  '/provider/$providerId/tools': typeof ProviderProviderIdToolsRouteRouteWithChildren
  '/admin/applications/$applicationId': typeof AdminApplicationsApplicationIdRoute
  '/admin/customers/$userId': typeof AdminCustomersUserIdRoute
  '/admin/providers/add': typeof AdminProvidersAddRoute
  '/provider/$providerId/start': typeof ProviderProviderIdStartRoute
  '/admin/applications/': typeof AdminApplicationsIndexRoute
  '/admin/configuration/': typeof AdminConfigurationIndexRoute
  '/admin/customers/': typeof AdminCustomersIndexRoute
  '/admin/deposit-accounts/': typeof AdminDepositAccountsIndexRoute
  '/admin/providers/': typeof AdminProvidersIndexRoute
  '/portal/deposit-account/': typeof PortalDepositAccountIndexRoute
  '/portal/documents/': typeof PortalDocumentsIndexRoute
  '/provider/$providerId/': typeof ProviderProviderIdIndexRoute
  '/portal/deposit-account/$depositAccountId/home': typeof PortalDepositAccountDepositAccountIdHomeRouteRouteWithChildren
  '/portal/deposit-account/$depositAccountId/new-transfer': typeof PortalDepositAccountDepositAccountIdNewTransferRouteRouteWithChildren
  '/admin/configuration/omnibus/$omnibusAccountId': typeof AdminConfigurationOmnibusOmnibusAccountIdRoute
  '/admin/configuration/proxy/$proxyId': typeof AdminConfigurationProxyProxyIdRoute
  '/admin/configuration/team/$userId': typeof AdminConfigurationTeamUserIdRoute
  '/portal/start/_guide/retirement-checking': typeof PortalStartGuideRetirementCheckingRoute
  '/portal/start/new/$depositAccountType': typeof PortalStartNewDepositAccountTypeRoute
  '/provider/$providerId/applications/$applicationId': typeof ProviderProviderIdApplicationsApplicationIdRoute
  '/provider/$providerId/team/$userId': typeof ProviderProviderIdTeamUserIdRoute
  '/admin/configuration/features/': typeof AdminConfigurationFeaturesIndexRoute
  '/admin/configuration/funding-accounts/': typeof AdminConfigurationFundingAccountsIndexRoute
  '/admin/configuration/omnibus/': typeof AdminConfigurationOmnibusIndexRoute
  '/admin/configuration/proxy/': typeof AdminConfigurationProxyIndexRoute
  '/admin/configuration/team/': typeof AdminConfigurationTeamIndexRoute
  '/admin/providers/$providerId/': typeof AdminProvidersProviderIdIndexRoute
  '/portal/deposit-account/$depositAccountId/': typeof PortalDepositAccountDepositAccountIdIndexRoute
  '/portal/start/_guide/': typeof PortalStartGuideIndexRoute
  '/portal/start/new/': typeof PortalStartNewIndexRoute
  '/provider/$providerId/applications/': typeof ProviderProviderIdApplicationsIndexRoute
  '/provider/$providerId/team/': typeof ProviderProviderIdTeamIndexRoute
  '/provider/$providerId/tools/': typeof ProviderProviderIdToolsIndexRoute
  '/admin/providers/$providerId/users/$userId': typeof AdminProvidersProviderIdUsersUserIdRouteRoute
  '/portal/deposit-account/$depositAccountId/home/ach': typeof PortalDepositAccountDepositAccountIdHomeAchRoute
  '/portal/deposit-account/$depositAccountId/home/transactions': typeof PortalDepositAccountDepositAccountIdHomeTransactionsRoute
  '/portal/deposit-account/$depositAccountId/home/wires': typeof PortalDepositAccountDepositAccountIdHomeWiresRoute
  '/portal/deposit-account/$depositAccountId/new-transfer/ach': typeof PortalDepositAccountDepositAccountIdNewTransferAchRoute
  '/portal/deposit-account/$depositAccountId/new-transfer/wire': typeof PortalDepositAccountDepositAccountIdNewTransferWireRoute
  '/provider/$providerId/applications/status/$applicationId': typeof ProviderProviderIdApplicationsStatusApplicationIdRoute
  '/portal/deposit-account/$depositAccountId/home/': typeof PortalDepositAccountDepositAccountIdHomeIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/admin'
    | '/onboarding'
    | '/portal'
    | '/start'
    | '/testing'
    | '/login'
    | '/logout'
    | '/welcome'
    | '/admin/applications'
    | '/admin/configuration'
    | '/admin/customers'
    | '/admin/deposit-accounts'
    | '/admin/providers'
    | '/portal/deposit-account'
    | '/portal/start'
    | '/provider/$providerId'
    | '/admin/overview'
    | '/application-status/$applicationId'
    | '/application/$applicationId'
    | '/testing/system-tools'
    | '/testing/users'
    | '/admin/'
    | '/portal/'
    | '/testing/'
    | '/admin/configuration/api-keys'
    | '/admin/configuration/funding-accounts'
    | '/admin/configuration/omnibus'
    | '/admin/configuration/proxy'
    | '/admin/configuration/team'
    | '/admin/providers/$providerId'
    | '/portal/deposit-account/$depositAccountId'
    | '/portal/start/new'
    | '/provider/$providerId/applications'
    | '/provider/$providerId/team'
    | '/provider/$providerId/tools'
    | '/admin/applications/$applicationId'
    | '/admin/customers/$userId'
    | '/admin/providers/add'
    | '/provider/$providerId/start'
    | '/admin/applications/'
    | '/admin/configuration/'
    | '/admin/customers/'
    | '/admin/deposit-accounts/'
    | '/admin/providers/'
    | '/portal/deposit-account/'
    | '/portal/documents'
    | '/provider/$providerId/'
    | '/portal/deposit-account/$depositAccountId/home'
    | '/portal/deposit-account/$depositAccountId/new-transfer'
    | '/admin/configuration/omnibus/$omnibusAccountId'
    | '/admin/configuration/proxy/$proxyId'
    | '/admin/configuration/team/$userId'
    | '/portal/start/retirement-checking'
    | '/portal/start/new/$depositAccountType'
    | '/provider/$providerId/applications/$applicationId'
    | '/provider/$providerId/team/$userId'
    | '/admin/configuration/features'
    | '/admin/configuration/funding-accounts/'
    | '/admin/configuration/omnibus/'
    | '/admin/configuration/proxy/'
    | '/admin/configuration/team/'
    | '/admin/providers/$providerId/'
    | '/portal/deposit-account/$depositAccountId/'
    | '/portal/start/'
    | '/portal/start/new/'
    | '/provider/$providerId/applications/'
    | '/provider/$providerId/team/'
    | '/provider/$providerId/tools/'
    | '/admin/providers/$providerId/users/$userId'
    | '/portal/deposit-account/$depositAccountId/home/ach'
    | '/portal/deposit-account/$depositAccountId/home/transactions'
    | '/portal/deposit-account/$depositAccountId/home/wires'
    | '/portal/deposit-account/$depositAccountId/new-transfer/ach'
    | '/portal/deposit-account/$depositAccountId/new-transfer/wire'
    | '/provider/$providerId/applications/status/$applicationId'
    | '/portal/deposit-account/$depositAccountId/home/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/onboarding'
    | '/start'
    | '/login'
    | '/logout'
    | '/welcome'
    | '/portal/start'
    | '/admin/overview'
    | '/application-status/$applicationId'
    | '/application/$applicationId'
    | '/testing/system-tools'
    | '/testing/users'
    | '/admin'
    | '/portal'
    | '/testing'
    | '/admin/configuration/api-keys'
    | '/admin/applications/$applicationId'
    | '/admin/customers/$userId'
    | '/admin/providers/add'
    | '/provider/$providerId/start'
    | '/admin/applications'
    | '/admin/configuration'
    | '/admin/customers'
    | '/admin/deposit-accounts'
    | '/admin/providers'
    | '/portal/deposit-account'
    | '/portal/documents'
    | '/provider/$providerId'
    | '/portal/deposit-account/$depositAccountId/new-transfer'
    | '/admin/configuration/omnibus/$omnibusAccountId'
    | '/admin/configuration/proxy/$proxyId'
    | '/admin/configuration/team/$userId'
    | '/portal/start/retirement-checking'
    | '/portal/start/new/$depositAccountType'
    | '/provider/$providerId/applications/$applicationId'
    | '/provider/$providerId/team/$userId'
    | '/admin/configuration/features'
    | '/admin/configuration/funding-accounts'
    | '/admin/configuration/omnibus'
    | '/admin/configuration/proxy'
    | '/admin/configuration/team'
    | '/admin/providers/$providerId'
    | '/portal/deposit-account/$depositAccountId'
    | '/portal/start/new'
    | '/provider/$providerId/applications'
    | '/provider/$providerId/team'
    | '/provider/$providerId/tools'
    | '/admin/providers/$providerId/users/$userId'
    | '/portal/deposit-account/$depositAccountId/home/ach'
    | '/portal/deposit-account/$depositAccountId/home/transactions'
    | '/portal/deposit-account/$depositAccountId/home/wires'
    | '/portal/deposit-account/$depositAccountId/new-transfer/ach'
    | '/portal/deposit-account/$depositAccountId/new-transfer/wire'
    | '/provider/$providerId/applications/status/$applicationId'
    | '/portal/deposit-account/$depositAccountId/home'
  id:
    | '__root__'
    | '/'
    | '/admin'
    | '/onboarding'
    | '/portal'
    | '/start'
    | '/testing'
    | '/login'
    | '/logout'
    | '/welcome'
    | '/admin/applications'
    | '/admin/configuration'
    | '/admin/customers'
    | '/admin/deposit-accounts'
    | '/admin/providers'
    | '/portal/deposit-account'
    | '/portal/start'
    | '/provider/$providerId'
    | '/admin/overview'
    | '/application-status/$applicationId'
    | '/application/$applicationId'
    | '/testing/system-tools'
    | '/testing/users'
    | '/admin/'
    | '/portal/'
    | '/testing/'
    | '/admin/configuration/api-keys'
    | '/admin/configuration/funding-accounts'
    | '/admin/configuration/omnibus'
    | '/admin/configuration/proxy'
    | '/admin/configuration/team'
    | '/admin/providers/$providerId'
    | '/portal/deposit-account/$depositAccountId'
    | '/portal/start/_guide'
    | '/portal/start/new'
    | '/provider/$providerId/applications'
    | '/provider/$providerId/team'
    | '/provider/$providerId/tools'
    | '/admin/applications/$applicationId'
    | '/admin/customers/$userId'
    | '/admin/providers/add'
    | '/provider/$providerId/start'
    | '/admin/applications/'
    | '/admin/configuration/'
    | '/admin/customers/'
    | '/admin/deposit-accounts/'
    | '/admin/providers/'
    | '/portal/deposit-account/'
    | '/portal/documents/'
    | '/provider/$providerId/'
    | '/portal/deposit-account/$depositAccountId/home'
    | '/portal/deposit-account/$depositAccountId/new-transfer'
    | '/admin/configuration/omnibus/$omnibusAccountId'
    | '/admin/configuration/proxy/$proxyId'
    | '/admin/configuration/team/$userId'
    | '/portal/start/_guide/retirement-checking'
    | '/portal/start/new/$depositAccountType'
    | '/provider/$providerId/applications/$applicationId'
    | '/provider/$providerId/team/$userId'
    | '/admin/configuration/features/'
    | '/admin/configuration/funding-accounts/'
    | '/admin/configuration/omnibus/'
    | '/admin/configuration/proxy/'
    | '/admin/configuration/team/'
    | '/admin/providers/$providerId/'
    | '/portal/deposit-account/$depositAccountId/'
    | '/portal/start/_guide/'
    | '/portal/start/new/'
    | '/provider/$providerId/applications/'
    | '/provider/$providerId/team/'
    | '/provider/$providerId/tools/'
    | '/admin/providers/$providerId/users/$userId'
    | '/portal/deposit-account/$depositAccountId/home/ach'
    | '/portal/deposit-account/$depositAccountId/home/transactions'
    | '/portal/deposit-account/$depositAccountId/home/wires'
    | '/portal/deposit-account/$depositAccountId/new-transfer/ach'
    | '/portal/deposit-account/$depositAccountId/new-transfer/wire'
    | '/provider/$providerId/applications/status/$applicationId'
    | '/portal/deposit-account/$depositAccountId/home/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AdminRouteRoute: typeof AdminRouteRouteWithChildren
  OnboardingRouteRoute: typeof OnboardingRouteRoute
  PortalRouteRoute: typeof PortalRouteRouteWithChildren
  StartRouteRoute: typeof StartRouteRoute
  TestingRouteRoute: typeof TestingRouteRouteWithChildren
  LoginRoute: typeof LoginRoute
  LogoutRoute: typeof LogoutRoute
  WelcomeRoute: typeof WelcomeRoute
  ProviderProviderIdRouteRoute: typeof ProviderProviderIdRouteRouteWithChildren
  ApplicationStatusApplicationIdRoute: typeof ApplicationStatusApplicationIdRoute
  ApplicationApplicationIdRoute: typeof ApplicationApplicationIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AdminRouteRoute: AdminRouteRouteWithChildren,
  OnboardingRouteRoute: OnboardingRouteRoute,
  PortalRouteRoute: PortalRouteRouteWithChildren,
  StartRouteRoute: StartRouteRoute,
  TestingRouteRoute: TestingRouteRouteWithChildren,
  LoginRoute: LoginRoute,
  LogoutRoute: LogoutRoute,
  WelcomeRoute: WelcomeRoute,
  ProviderProviderIdRouteRoute: ProviderProviderIdRouteRouteWithChildren,
  ApplicationStatusApplicationIdRoute: ApplicationStatusApplicationIdRoute,
  ApplicationApplicationIdRoute: ApplicationApplicationIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/admin",
        "/onboarding",
        "/portal",
        "/start",
        "/testing",
        "/login",
        "/logout",
        "/welcome",
        "/provider/$providerId",
        "/application-status/$applicationId",
        "/application/$applicationId"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/admin": {
      "filePath": "admin/route.tsx",
      "children": [
        "/admin/applications",
        "/admin/configuration",
        "/admin/customers",
        "/admin/deposit-accounts",
        "/admin/providers",
        "/admin/overview",
        "/admin/"
      ]
    },
    "/onboarding": {
      "filePath": "onboarding/route.tsx"
    },
    "/portal": {
      "filePath": "portal/route.tsx",
      "children": [
        "/portal/deposit-account",
        "/portal/start",
        "/portal/",
        "/portal/documents/"
      ]
    },
    "/start": {
      "filePath": "start/route.tsx"
    },
    "/testing": {
      "filePath": "testing/route.tsx",
      "children": [
        "/testing/system-tools",
        "/testing/users",
        "/testing/"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/logout": {
      "filePath": "logout.tsx"
    },
    "/welcome": {
      "filePath": "welcome.tsx"
    },
    "/admin/applications": {
      "filePath": "admin/applications/route.tsx",
      "parent": "/admin",
      "children": [
        "/admin/applications/$applicationId",
        "/admin/applications/"
      ]
    },
    "/admin/configuration": {
      "filePath": "admin/configuration/route.tsx",
      "parent": "/admin",
      "children": [
        "/admin/configuration/api-keys",
        "/admin/configuration/funding-accounts",
        "/admin/configuration/omnibus",
        "/admin/configuration/proxy",
        "/admin/configuration/team",
        "/admin/configuration/",
        "/admin/configuration/features/"
      ]
    },
    "/admin/customers": {
      "filePath": "admin/customers/route.tsx",
      "parent": "/admin",
      "children": [
        "/admin/customers/$userId",
        "/admin/customers/"
      ]
    },
    "/admin/deposit-accounts": {
      "filePath": "admin/deposit-accounts/route.tsx",
      "parent": "/admin",
      "children": [
        "/admin/deposit-accounts/"
      ]
    },
    "/admin/providers": {
      "filePath": "admin/providers/route.tsx",
      "parent": "/admin",
      "children": [
        "/admin/providers/$providerId",
        "/admin/providers/add",
        "/admin/providers/"
      ]
    },
    "/portal/deposit-account": {
      "filePath": "portal/deposit-account/route.tsx",
      "parent": "/portal",
      "children": [
        "/portal/deposit-account/$depositAccountId",
        "/portal/deposit-account/"
      ]
    },
    "/portal/start": {
      "filePath": "portal/start/route.tsx",
      "parent": "/portal",
      "children": [
        "/portal/start/_guide",
        "/portal/start/new"
      ]
    },
    "/provider/$providerId": {
      "filePath": "provider.$providerId/route.tsx",
      "children": [
        "/provider/$providerId/applications",
        "/provider/$providerId/team",
        "/provider/$providerId/tools",
        "/provider/$providerId/start",
        "/provider/$providerId/"
      ]
    },
    "/admin/overview": {
      "filePath": "admin/overview.tsx",
      "parent": "/admin"
    },
    "/application-status/$applicationId": {
      "filePath": "application-status/$applicationId.tsx"
    },
    "/application/$applicationId": {
      "filePath": "application/$applicationId.tsx"
    },
    "/testing/system-tools": {
      "filePath": "testing/system-tools.tsx",
      "parent": "/testing"
    },
    "/testing/users": {
      "filePath": "testing/users.tsx",
      "parent": "/testing"
    },
    "/admin/": {
      "filePath": "admin/index.tsx",
      "parent": "/admin"
    },
    "/portal/": {
      "filePath": "portal/index.tsx",
      "parent": "/portal"
    },
    "/testing/": {
      "filePath": "testing/index.tsx",
      "parent": "/testing"
    },
    "/admin/configuration/api-keys": {
      "filePath": "admin/configuration/api-keys/route.tsx",
      "parent": "/admin/configuration"
    },
    "/admin/configuration/funding-accounts": {
      "filePath": "admin/configuration/funding-accounts/route.tsx",
      "parent": "/admin/configuration",
      "children": [
        "/admin/configuration/funding-accounts/"
      ]
    },
    "/admin/configuration/omnibus": {
      "filePath": "admin/configuration/omnibus/route.tsx",
      "parent": "/admin/configuration",
      "children": [
        "/admin/configuration/omnibus/$omnibusAccountId",
        "/admin/configuration/omnibus/"
      ]
    },
    "/admin/configuration/proxy": {
      "filePath": "admin/configuration/proxy/route.tsx",
      "parent": "/admin/configuration",
      "children": [
        "/admin/configuration/proxy/$proxyId",
        "/admin/configuration/proxy/"
      ]
    },
    "/admin/configuration/team": {
      "filePath": "admin/configuration/team/route.tsx",
      "parent": "/admin/configuration",
      "children": [
        "/admin/configuration/team/$userId",
        "/admin/configuration/team/"
      ]
    },
    "/admin/providers/$providerId": {
      "filePath": "admin/providers/$providerId/route.tsx",
      "parent": "/admin/providers",
      "children": [
        "/admin/providers/$providerId/",
        "/admin/providers/$providerId/users/$userId"
      ]
    },
    "/portal/deposit-account/$depositAccountId": {
      "filePath": "portal/deposit-account/$depositAccountId/route.tsx",
      "parent": "/portal/deposit-account",
      "children": [
        "/portal/deposit-account/$depositAccountId/home",
        "/portal/deposit-account/$depositAccountId/new-transfer",
        "/portal/deposit-account/$depositAccountId/"
      ]
    },
    "/portal/start/_guide": {
      "filePath": "portal/start/_guide/route.tsx",
      "parent": "/portal/start",
      "children": [
        "/portal/start/_guide/retirement-checking",
        "/portal/start/_guide/"
      ]
    },
    "/portal/start/new": {
      "filePath": "portal/start/new/route.tsx",
      "parent": "/portal/start",
      "children": [
        "/portal/start/new/$depositAccountType",
        "/portal/start/new/"
      ]
    },
    "/provider/$providerId/applications": {
      "filePath": "provider.$providerId/applications/route.tsx",
      "parent": "/provider/$providerId",
      "children": [
        "/provider/$providerId/applications/$applicationId",
        "/provider/$providerId/applications/",
        "/provider/$providerId/applications/status/$applicationId"
      ]
    },
    "/provider/$providerId/team": {
      "filePath": "provider.$providerId/team/route.tsx",
      "parent": "/provider/$providerId",
      "children": [
        "/provider/$providerId/team/$userId",
        "/provider/$providerId/team/"
      ]
    },
    "/provider/$providerId/tools": {
      "filePath": "provider.$providerId/tools/route.tsx",
      "parent": "/provider/$providerId",
      "children": [
        "/provider/$providerId/tools/"
      ]
    },
    "/admin/applications/$applicationId": {
      "filePath": "admin/applications/$applicationId.tsx",
      "parent": "/admin/applications"
    },
    "/admin/customers/$userId": {
      "filePath": "admin/customers/$userId.tsx",
      "parent": "/admin/customers"
    },
    "/admin/providers/add": {
      "filePath": "admin/providers/add.tsx",
      "parent": "/admin/providers"
    },
    "/provider/$providerId/start": {
      "filePath": "provider.$providerId/start.tsx",
      "parent": "/provider/$providerId"
    },
    "/admin/applications/": {
      "filePath": "admin/applications/index.tsx",
      "parent": "/admin/applications"
    },
    "/admin/configuration/": {
      "filePath": "admin/configuration/index.tsx",
      "parent": "/admin/configuration"
    },
    "/admin/customers/": {
      "filePath": "admin/customers/index.tsx",
      "parent": "/admin/customers"
    },
    "/admin/deposit-accounts/": {
      "filePath": "admin/deposit-accounts/index.tsx",
      "parent": "/admin/deposit-accounts"
    },
    "/admin/providers/": {
      "filePath": "admin/providers/index.tsx",
      "parent": "/admin/providers"
    },
    "/portal/deposit-account/": {
      "filePath": "portal/deposit-account/index.tsx",
      "parent": "/portal/deposit-account"
    },
    "/portal/documents/": {
      "filePath": "portal/documents/index.tsx",
      "parent": "/portal"
    },
    "/provider/$providerId/": {
      "filePath": "provider.$providerId/index.tsx",
      "parent": "/provider/$providerId"
    },
    "/portal/deposit-account/$depositAccountId/home": {
      "filePath": "portal/deposit-account/$depositAccountId/home/route.tsx",
      "parent": "/portal/deposit-account/$depositAccountId",
      "children": [
        "/portal/deposit-account/$depositAccountId/home/ach",
        "/portal/deposit-account/$depositAccountId/home/transactions",
        "/portal/deposit-account/$depositAccountId/home/wires",
        "/portal/deposit-account/$depositAccountId/home/"
      ]
    },
    "/portal/deposit-account/$depositAccountId/new-transfer": {
      "filePath": "portal/deposit-account/$depositAccountId/new-transfer/route.tsx",
      "parent": "/portal/deposit-account/$depositAccountId",
      "children": [
        "/portal/deposit-account/$depositAccountId/new-transfer/ach",
        "/portal/deposit-account/$depositAccountId/new-transfer/wire"
      ]
    },
    "/admin/configuration/omnibus/$omnibusAccountId": {
      "filePath": "admin/configuration/omnibus/$omnibusAccountId.tsx",
      "parent": "/admin/configuration/omnibus"
    },
    "/admin/configuration/proxy/$proxyId": {
      "filePath": "admin/configuration/proxy/$proxyId.tsx",
      "parent": "/admin/configuration/proxy"
    },
    "/admin/configuration/team/$userId": {
      "filePath": "admin/configuration/team/$userId.tsx",
      "parent": "/admin/configuration/team"
    },
    "/portal/start/_guide/retirement-checking": {
      "filePath": "portal/start/_guide/retirement-checking.tsx",
      "parent": "/portal/start/_guide"
    },
    "/portal/start/new/$depositAccountType": {
      "filePath": "portal/start/new/$depositAccountType.tsx",
      "parent": "/portal/start/new"
    },
    "/provider/$providerId/applications/$applicationId": {
      "filePath": "provider.$providerId/applications/$applicationId.tsx",
      "parent": "/provider/$providerId/applications"
    },
    "/provider/$providerId/team/$userId": {
      "filePath": "provider.$providerId/team/$userId.tsx",
      "parent": "/provider/$providerId/team"
    },
    "/admin/configuration/features/": {
      "filePath": "admin/configuration/features/index.tsx",
      "parent": "/admin/configuration"
    },
    "/admin/configuration/funding-accounts/": {
      "filePath": "admin/configuration/funding-accounts/index.tsx",
      "parent": "/admin/configuration/funding-accounts"
    },
    "/admin/configuration/omnibus/": {
      "filePath": "admin/configuration/omnibus/index.tsx",
      "parent": "/admin/configuration/omnibus"
    },
    "/admin/configuration/proxy/": {
      "filePath": "admin/configuration/proxy/index.tsx",
      "parent": "/admin/configuration/proxy"
    },
    "/admin/configuration/team/": {
      "filePath": "admin/configuration/team/index.tsx",
      "parent": "/admin/configuration/team"
    },
    "/admin/providers/$providerId/": {
      "filePath": "admin/providers/$providerId/index.tsx",
      "parent": "/admin/providers/$providerId"
    },
    "/portal/deposit-account/$depositAccountId/": {
      "filePath": "portal/deposit-account/$depositAccountId/index.tsx",
      "parent": "/portal/deposit-account/$depositAccountId"
    },
    "/portal/start/_guide/": {
      "filePath": "portal/start/_guide/index.tsx",
      "parent": "/portal/start/_guide"
    },
    "/portal/start/new/": {
      "filePath": "portal/start/new/index.tsx",
      "parent": "/portal/start/new"
    },
    "/provider/$providerId/applications/": {
      "filePath": "provider.$providerId/applications/index.tsx",
      "parent": "/provider/$providerId/applications"
    },
    "/provider/$providerId/team/": {
      "filePath": "provider.$providerId/team/index.tsx",
      "parent": "/provider/$providerId/team"
    },
    "/provider/$providerId/tools/": {
      "filePath": "provider.$providerId/tools/index.tsx",
      "parent": "/provider/$providerId/tools"
    },
    "/admin/providers/$providerId/users/$userId": {
      "filePath": "admin/providers/$providerId/users.$userId.route.tsx",
      "parent": "/admin/providers/$providerId"
    },
    "/portal/deposit-account/$depositAccountId/home/ach": {
      "filePath": "portal/deposit-account/$depositAccountId/home/ach.tsx",
      "parent": "/portal/deposit-account/$depositAccountId/home"
    },
    "/portal/deposit-account/$depositAccountId/home/transactions": {
      "filePath": "portal/deposit-account/$depositAccountId/home/transactions.tsx",
      "parent": "/portal/deposit-account/$depositAccountId/home"
    },
    "/portal/deposit-account/$depositAccountId/home/wires": {
      "filePath": "portal/deposit-account/$depositAccountId/home/wires.tsx",
      "parent": "/portal/deposit-account/$depositAccountId/home"
    },
    "/portal/deposit-account/$depositAccountId/new-transfer/ach": {
      "filePath": "portal/deposit-account/$depositAccountId/new-transfer/ach.tsx",
      "parent": "/portal/deposit-account/$depositAccountId/new-transfer"
    },
    "/portal/deposit-account/$depositAccountId/new-transfer/wire": {
      "filePath": "portal/deposit-account/$depositAccountId/new-transfer/wire.tsx",
      "parent": "/portal/deposit-account/$depositAccountId/new-transfer"
    },
    "/provider/$providerId/applications/status/$applicationId": {
      "filePath": "provider.$providerId/applications/status.$applicationId.tsx",
      "parent": "/provider/$providerId/applications"
    },
    "/portal/deposit-account/$depositAccountId/home/": {
      "filePath": "portal/deposit-account/$depositAccountId/home/index.tsx",
      "parent": "/portal/deposit-account/$depositAccountId/home"
    }
  }
}
ROUTE_MANIFEST_END */
