import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { Fragment, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { listCustomersQuery } from '@/features/administrator-panel/customer/api/customersApi';
import { useCustomersListState } from '@/features/administrator-panel/customer/state';
import {
  CustomerListItem,
  CustomerListItemSkeletons,
} from '@/features/administrator-panel/customer/ui/CustomerListItem.tsx';
import { useSearch } from '@tanstack/react-router';

export const CUSTOMERS_PER_PAGE = 20;

export const CustomersList = () => {
  const { ref, inView } = useInView();
  const [setTotalItems, setShowLoader] = useCustomersListState((state) => [
    state.setTotalItems,
    state.setShowLoader,
  ]);

  const { search, accountsFilters } = useSearch({from: '/admin/customers/'});

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isFetching } =
    useSuspenseInfiniteQuery(
      listCustomersQuery({
        perPage: CUSTOMERS_PER_PAGE,
        search,
        hasAccounts: (accountsFilters === undefined || !accountsFilters.length)
          ? undefined
          : accountsFilters.includes('HAS_ACCOUNTS'),
      }),
    );

  useEffect(() => {
    setShowLoader(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (data.pages[0]) {
      setTotalItems(data.pages[0].total);
    } else {
      setTotalItems(0);
    }
  }, [data.pages[0]]);

  const numLoadedItems = data.pages.reduce(
    (acc, page) => acc + page.items.length,
    0,
  );
  const remainingItems = (data.pages[0]?.total || 0) - numLoadedItems;

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      void fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, inView, isFetchingNextPage]);

  return (
    <div className={'divide-y divide-gray-200'}>
      {data.pages.map((page) => (
        <Fragment key={search + '-' + page.currentCursor}>
          {page.items.map((customer) => (
            <CustomerListItem customer={customer} key={customer.id} />
          ))}
        </Fragment>
      ))}

      {isFetchingNextPage && (
        <CustomerListItemSkeletons
          count={Math.min(CUSTOMERS_PER_PAGE, remainingItems)}
        />
      )}

      <span ref={ref} className={'invisible'} />
    </div>
  );
};
