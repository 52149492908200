import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient';
import { listTestUsersQuery } from '@/testing/api';
import { TestingUsers } from '@/testing/TestingUsers.tsx';

export const Route = createFileRoute('/testing/users')({
  component: TestingUsers,
  head: () => ({ meta: [{ title: 'Users' }] }),
  loader: () => crownQueryClient.ensureQueryData(listTestUsersQuery),
});
