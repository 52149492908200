import { Key } from '@phosphor-icons/react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

import { crownApi, crownQueryClient } from '@/common/api/crownQueryClient';
import { Button } from '@/components/ui/Button.tsx';
import Dialog from '@/components/ui/Dialog.tsx';
import { Input } from '@/components/ui/Input.tsx';
import { Typography } from '@/components/ui/Typography.tsx';

type CreateAPIKeyDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const CreateAPIKeyDialog = ({
  open,
  onClose,
}: CreateAPIKeyDialogProps) => {
  const [description, setDescription] = useState('');

  const { mutate, isPending } = useMutation({
    mutationFn: async (description: string) =>
      (
        await crownApi.APIKeysAdmin_createKey(null, {
          description,
        })
      ).data,
    onSuccess: () => {
      toast.success('API Key created');
      setDescription('');
      void crownQueryClient.invalidateQueries({
        queryKey: ['admin', 'keys'],
      });
      onClose();
    },
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <Typography variant={'h2'} className={'text-center'}>
        <Key className={'mr-2 inline size-8'} /> Create API Key
      </Typography>

      <Input
        size={'lg'}
        className={'mt-4'}
        label={'Description (optional)'}
        value={description}
        helperText={
          'Optionally add a short description for the purpose of the key and where it will be used.'
        }
        onChange={(e) => setDescription(e.target.value)}
      />

      <div className={'mt-8 flex items-center justify-end'}>
        <Button
          variant={'outline'}
          className={'mr-2'}
          type={'button'}
          onClick={() => onClose()}
        >
          Close
        </Button>
        <Button
          type={'button'}
          onClick={() => mutate(description)}
          loading={isPending}
        >
          Create Key
        </Button>
      </div>
    </Dialog>
  );
};
