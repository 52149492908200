import { createFileRoute, stripSearchParams } from '@tanstack/react-router';

import { ProviderView } from '@/features/administrator-panel/provider-view/ProviderView.tsx';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';
import { getZodDefaults } from '@/common/util/getZodDefaults';

export const providerTabs = ['details', 'configuration', 'portal'] as const;

const routeSchema = z.object({
  providerTab: z.enum(providerTabs).optional().default('details'),
})


export const Route = createFileRoute('/admin/providers/$providerId/')({
  validateSearch: zodValidator(routeSchema),
  search: {
    middlewares: [stripSearchParams(getZodDefaults(routeSchema))]
  },
  component: ProviderView,
});
