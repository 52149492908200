import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient';
import { listDepositAccountsQuery } from '@/features/administrator-panel/deposit-accounts/api/depositAccountsApi';
import {
  DEPOSIT_ACCOUNTS_PER_PAGE,
  DepositAccounts,
} from '@/features/administrator-panel/deposit-accounts/DepositAccounts.tsx';

export const Route = createFileRoute('/admin/deposit-accounts/')({
  validateSearch: (search: Record<string, unknown>) => {
    return {
      search: (search.search as string) || undefined,
    };
  },
  loader: () =>
    crownQueryClient.ensureInfiniteQueryData(
      listDepositAccountsQuery({ limit: DEPOSIT_ACCOUNTS_PER_PAGE }),
    ),
  component: DepositAccounts,
});
