import { queryOptions } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient';

export const getApplicationStatusOptions = (
  providerId: string,
  applicationId: string,
) =>
  queryOptions({
    queryKey: ['application-status', providerId, applicationId],
    queryFn: async () =>
      (
        await crownApi.ProviderApplications_getApplication({
          providerId,
          applicationId,
        })
      ).data,
  });
