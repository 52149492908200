import { createFileRoute, redirect } from '@tanstack/react-router';
import { crownQueryClient } from '@/common/api/crownQueryClient';
import { queryUserDepositAccounts } from '@/features/portal/deposit-accounts/api/user-deposit-accounts';
import { getDepositAccountsCount } from '@/features/portal/api/user-deposit-accounts';
import { DepositAccountsIndex } from '@/features/portal/deposit-accounts/DepositAccountIndexList.tsx';

export const Route = createFileRoute('/portal/deposit-account/')({
  beforeLoad: async () => {
    const { count } = await crownQueryClient.fetchQuery(getDepositAccountsCount);

    if (!count) {
      throw redirect({
        to: '/portal/start',
        replace: true,
      });
    }

  },
  loader: () => crownQueryClient.ensureQueryData(queryUserDepositAccounts),
  component:  DepositAccountsIndex
});
